import { useState } from "react";
import { useQuery } from "react-query";
import { getCurrentUser } from "../api/users";

export default function useCurrentUser() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const { data, isLoading, isError } = useQuery(
    "currentUser",
    async () => {
      const res = await getCurrentUser();
      return res;
    },
    {
      onSuccess: (data) => {
        if (data.role === "admin" || data.role === "superadmin")
          setIsAdmin(true);

        if (data.role === "superadmin") setIsSuperAdmin(true);
      },
    }
  );

  return { data, isLoading, isError, isAdmin, isSuperAdmin };
}
