import { useState } from "react";
import { useNavigate } from "react-router-dom";

// ui
import { Spinner } from "flowbite-react";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineClose,
  AiOutlineCheck,
} from "react-icons/ai";
import { FiTrash2, FiPlus, FiDownload } from "react-icons/fi";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { BiCollapseHorizontal, BiExpandHorizontal } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { Tooltip } from "flowbite-react";
import ErrorModal from "../modals/ErrorModal";
import DeleteOne from "./DeleteOne";
import DeleteMany from "./DeleteMany";

// packages
import clsx from "clsx";
import { useMutation, useQueryClient } from "react-query";

// api
import { editPart } from "../../api/participants";
import DownloadModal from "./downloadModal";

// hooks
import useModal from "../../hooks/useModal";
import useCurrentUser from "../../hooks/useCurrentUser";

const PartTable = ({ eventId, headers, data, sort }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isAdmin } = useCurrentUser();

  const errorModal = useModal(); // error modal when edit fails
  const deleteOneModal = useModal(); // delete one part modal
  const deleteManyModal = useModal(); // delete many part modal
  const downloadModal = useModal(); // download part modal

  const { sortHeader, setSortHeader } = sort;

  const [selectedItem, setSelectedItem] = useState([]); // selected row for edit

  // edit participant
  const [editingItemId, setEditingItemId] = useState(""); // selected row item's id
  const [editItem, setEditItem] = useState({}); // editing item data
  const [editedRow, setEditedRow] = useState(""); // latest row that's been edited

  // delete participant
  const [deleteOneData, setDeleteOneData] = useState({}); // delete one part data
  const [deleteManyData, setDeleteManyData] = useState([]); // delete many part data

  const [breakAllWords, setBreakAllWords] = useState(false);

  const breakBtnOnClick = () => {
    if (breakAllWords) setBreakAllWords(false);
    else setBreakAllWords(true);
  };

  //when checkbox is checked
  const checkboxOnChange = (e, data) => {
    const { name, checked } = e.target;

    if (checked) {
      if (name === "allSelect") {
        setSelectedItem(data);
      } else {
        setSelectedItem([...selectedItem, data]);
      }
    } else {
      if (name === "allSelect") setSelectedItem([]);
      else {
        let updatedList = selectedItem.filter((item) => item._id !== data._id);
        setSelectedItem(updatedList);
      }
    }
  };

  // when edit btn/pencil icon is clicked
  const editBtnOnClick = (id, item) => {
    setEditingItemId(id);
    setEditItem(item);
  };

  //   onChangeHandler for table data input
  const itemOnChange = (e, key) => {
    const value = e.target.value;
    setEditItem((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  // edit mutation for table data input
  const editMutation = useMutation(async ({ part, id }) => {
    const res = await editPart(part, id);
    return res;
  });

  const { isLoading: isEditLoading, reset: editReset } = editMutation;

  //  when save btn is clicked
  const saveEdit = (part, id) => {
    editMutation.mutate(
      { part, id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("parts");
          toast.success("Updated successfully");
          setEditingItemId("");
          setEditedRow(id);
          setTimeout(() => {
            setEditedRow("");
          }, 1000);
        },
        onError: () => {
          errorModal.onOpen();
        },
      }
    );
  };

  // when delete buton on item row is clicked
  const deleteOnePart = (data) => {
    const regex = /name|nama/;

    const name = Object.keys(data).find((key) => regex.test(key));

    const nameValue = name ? data[name] : data._id;
    setDeleteOneData({ id: data._id, name: nameValue });

    deleteOneModal.onOpen();
  };

  // when delete many button is clicked
  const deleteManyPart = (partList) => {
    const regex = /name|nama/; // Regular expression to match "name" or "nama"

    const filteredList = partList.map((item) => {
      const name = Object.keys(item).find((key) => regex.test(key));
      const nameValue = name ? item[name] : item._id;
      return { id: item._id, name: nameValue };
    });

    setDeleteManyData(filteredList);
    deleteManyModal.onOpen();
  };

  // when add button is clicked
  const navigateToUpdatePart = () => {
    navigate(`/events/parts/${eventId}/update`);
  };

  // when header is clicked
  const sortByHeader = (header) => {
    if (sortHeader.header === header) {
      if (sortHeader.order === 0) setSortHeader({ header: header, order: 1 });
      else setSortHeader({ header: header, order: 0 });
    } else setSortHeader({ header: header, order: 0 });
  };

  return (
    <div className="h-[750px] mt-4 flex flex-col">
      {/* DATA LENGTH, UPDATE, DELETE AND DOWNLOAD BTNS */}

      <div className="flex flex-col-reverse items-start justify-start mt-4 mb-2 md:items-end md:justify-between md:flex-row md:flex-wrap">
        {/* DATA AND SELECTED DATA LENGTH */}
        <div className="flex gap-2">
          <h1>Showing {data?.length} rows,</h1>
          <h1 className="italic text-blue-500">
            {selectedItem.length} selected
          </h1>
        </div>
        {/* END OF DATA AND SELECTED DATA LENGTH */}

        {/* UPLOAD, DELETE AND DOWNLOAD BUTTONS */}
        <div className="z-20 flex flex-row gap-3 my-2 w-fit ">
          {isAdmin && (
            <Tooltip
              content="Upload"
              placement="bottom"
              className="md:block"
            >
              <button
                onClick={navigateToUpdatePart}
                className="p-3 text-white bg-blue-500 rounded-full shadow-md hover:bg-blue-600"
              >
                <FiPlus size={20} />
              </button>
            </Tooltip>
          )}
          {selectedItem.length > 0 && (
            <Tooltip
              content="Download"
              placement="bottom"
            >
              <button
                onClick={() => downloadModal.onOpen()}
                className="p-3 text-white bg-purple-500 rounded-full shadow-md hover:bg-purple-600"
              >
                <FiDownload size={20} />
              </button>
            </Tooltip>
          )}

          {selectedItem.length > 0 && isAdmin && (
            <Tooltip
              content="Delete"
              placement="bottom"
            >
              <button
                onClick={() => deleteManyPart(selectedItem)}
                className="p-3 text-white bg-red-500 rounded-full shadow-md hover:bg-red-600"
              >
                <FiTrash2 size={20} />
              </button>
            </Tooltip>
          )}
        </div>
        {/*END OF UPLOAD, DELETE AND DOWNLOAD BUTTONS */}
      </div>
      {/* END OF DATA LENGTH, UPDATE, DELETE AND DOWNLOAD BTNS */}

      {/* TABLE CONTAINER */}
      <div className="relative w-full max-h-full overflow-x-auto shadow-md sm:rounded-lg h-fit">
        <table className="w-full text-sm text-left bg-white">
          <thead className="sticky top-0 text-xs text-white uppercase bg-indigo-500">
            <tr>
              {/* ROW INDEX */}
              <th
                scope="col"
                className="py-3 pl-4"
              >
              {/* BREAK WORDS IN TABLE */}
                <div
                  onClick={breakBtnOnClick}
                  className="hidden hover:cursor-pointer sm:block"
                >
                  {!breakAllWords ? (
                    <BiCollapseHorizontal size={18} />
                  ) : (
                    <BiExpandHorizontal size={18} />
                  )}
                </div>
              </th>
              {/* EDIT AND DELETE */}
              {isAdmin && (
                <th
                  scope="col"
                  className="px-2 py-3"
                ></th>
              )}
              {/* CHECKBOX */}
              <th
                scope="col"
                className="p-4"
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="allSelect"
                    onChange={(e) => checkboxOnChange(e, data)}
                    checked={selectedItem.length === data.length}
                    className={clsx(
                      data.length === 0 ? "hidden" : "block",
                      "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    )}
                  />
                  <label className="sr-only">checkbox</label>
                </div>
              </th>
              {/* DATA HEADERS */}
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3"
                >
                  <div
                    onClick={() => sortByHeader(header)}
                    className={clsx(
                      "flex items-center gap-2 hover:cursor-pointer w-fit px-2 py-1 rounded-lg hover:bg-white/70 hover:text-blue-500 select-none",
                      sortHeader.header === header
                        ? " bg-white/90  text-blue-500"
                        : "text-white"
                    )}
                  >
                    {header}
                    {sortHeader.header === header && sortHeader.order === 0 ? (
                      <FaSortUp />
                    ) : sortHeader.header === header &&
                      sortHeader.order === 1 ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </div>
                </th>
              ))}
              {/* END OF DATA HEADERS */}
            </tr>
          </thead>
          <tbody className="text-zinc-800">
            {data.map((item, index) => (
              <tr
                className={clsx(
                  "border-b ",
                  selectedItem.includes(item)
                    ? "bg-blue-100 hover:bg-blue-200"
                    : "bg-white hover:bg-zinc-50",
                  editedRow === item._id
                    ? "bg-blue-400 transition duration-700 animate-pulse ease text-black"
                    : "bg-white hover:bg-gray-50"
                )}
                key={index}
              >
                {/* ROW INDEX */}
                <td className="w-8 py-4 pl-4 text-zinc-500">{index + 1}</td>
                {/* EDIT AND DELETE BUTTONS */}
                {isAdmin && (
                  <td className="w-12 px-2 py-4 border-r border-zinc-100">
                    {editingItemId === item._id && isEditLoading ? (
                      <Spinner />
                    ) : editingItemId === item._id ? (
                      <div className="flex justify-center gap-4">
                        <AiOutlineClose
                          className="text-red-500 hover:cursor-pointer"
                          onClick={() => {
                            setEditingItemId("");
                          }}
                        />
                        <AiOutlineCheck
                          className="text-blue-500 hover:cursor-pointer"
                          onClick={() => saveEdit(editItem, item._id)}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center gap-4">
                        <AiOutlineEdit
                          className="hover:text-blue-500 hover:cursor-pointer"
                          onClick={() => editBtnOnClick(item._id, item)}
                        />
                        <AiOutlineDelete
                          onClick={() => deleteOnePart(item)}
                          className="hover:text-red-500 hover:cursor-pointer"
                        />
                      </div>
                    )}
                  </td>
                )}
                {/* END OF EDIT AND DELETE BUTTONS */}

                {/* CHECKBOX */}
                <td className="p-4">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="singleSelect"
                      onChange={(e) => checkboxOnChange(e, item)}
                      checked={selectedItem.some(
                        (person) => person?._id === item._id
                      )}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                    />
                    <label className="sr-only">checkbox</label>
                  </div>
                </td>
                {/* END OF CHECKBOX */}

                {/* PARTICIPANT DATA */}
                {Object.keys(item).map(
                  (key, index) =>
                    key !== "_id" && (
                      <td
                        className={clsx(
                          !breakAllWords ? "sm:whitespace-nowrap" : "",
                          "px-8 py-4 text-zinc-600 md:break-all",
                          index === 0 ? "font-semibold" : "font-normal"
                        )}
                        key={index}
                      >
                        {editingItemId === item._id ? (
                          <textarea
                            className="w-full px-3 py-2 bg-white border border-gray-400 rounded outline-none resize-none focus:resize-y"
                            value={editItem[key] || ""}
                            onChange={(e) => {
                              itemOnChange(e, key);
                            }}
                          />
                        ) : (
                          item[key]
                        )}
                      </td>
                    )
                )}
                {/* END OF PARTICIPANT DATA */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* END OF TABLE CONTAINER */}

      {/* DELETE ONE PARTICIPANT MODAL */}
      <DeleteOne
        deleteModal={deleteOneModal}
        data={deleteOneData}
      />

      {/* DELETE MULTIPLE PARTICIPANT MODAL */}
      <DeleteMany
        deleteModal={deleteManyModal}
        data={{ event: eventId, parts: deleteManyData }}
        clear={{ setSelectedItem, setDeleteManyData }}
      />

      {/* ERROR MODAL */}
      <ErrorModal
        errorModal={errorModal}
        reset={editReset}
      />

      {/* DOWNLOAD MODAL */}
      <DownloadModal
        downloadModal={downloadModal}
        data={selectedItem}
      />
    </div>
  );
};

export default PartTable;
