import toast from "react-hot-toast";
import DeleteModal from "../modals/DeleteModal";
import { deleteOne as deleteFn } from "../../api/users";
import { useMutation, useQueryClient } from "react-query";

export default function DeleteOne({ deleteModal, data, rightDrawer }) {
  const { id, name } = data;
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(async (id) => {
    await deleteFn(id);
  });

  const { isLoading, isError, reset } = deleteMutation;

  const deleteHandler = (id) => {
    deleteMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        deleteModal.onClose();
        rightDrawer.closeRightDrawer();
        toast.success("Deleted.");
      },
    });
  };

  return (
    <DeleteModal
      deleteOne={true}
      deleteModal={deleteModal}
      isLoading={isLoading}
      isError={isError}
      reset={reset}
      onConfirm={() => deleteHandler(id)}
      data={[name]}
    />
  );
}
