import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ui
import MainContainer from "../../components/MainContainer";
import PageTitle from "../../components/PageTitle";
import { BsDownload } from "react-icons/bs";
import { Alert, Checkbox, Spinner } from "flowbite-react";
import { toast } from "react-hot-toast";
import LoadingBar from "../../components/LoadingBar";
import IsError from "../../components/IsError";

// packages
import { useMutation, useQuery } from "react-query";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";

// hooks
import useRightDrawer from "../../hooks/useRightDrawer";

// api
import { getEventById, updateEvent } from "../../api/events";

const UpdatePart = () => {
  const rightDrawer = useRightDrawer();
  rightDrawer.isOpen = false;

  const navigate = useNavigate();

  const { id } = useParams();

  // current event
  const [eventName, setEventName] = useState(""); //event name
  const [prevHeaders, setPrevHeaders] = useState([]); // headers from previous parts
  const [prevParts, setPrevParts] = useState([]); // previous parts
  const [prevUniqueHeader, setPrevUniqueHeader] = useState("");

  // select file
  const [data, setData] = useState([]); //data to be uploaded
  const [currHeaders, setCurrHeaders] = useState([]); //selected file headers
  const [addHeaders, setAddHeaders] = useState([]); //additional headers from selected file
  const [emptyUniqueValues, setEmptyUniqueValues] = useState([]); //unique header contains empty value
  const [duplicateUniqueValues, setDuplicateUniqueValues] = useState([]);
  const [missingHeaders, setMissingHeaders] = useState([]); // prevHeaders that are not present in selected file
  const [allMatch, setAllMatch] = useState(false); //prevParts already contain data

  const [selected, setSelected] = useState([]); //selected headers

  const [file, setFile] = useState(); //drag and drop file

  // fetch event from id
  const { isLoading: isGetEventLoading, isError: isGetEventError } = useQuery(
    "eventWithId",
    async () => {
      const res = await getEventById(id);
      return res;
    },
    {
      onSuccess: (data) => {
        setEventName(data.name);
        setPrevHeaders(getPartHeaders(data.parts));
        setPrevParts(data.parts);
        setPrevUniqueHeader(data.uniqueHeader);
      },
    }
  );

  // get headers of event/prevParts
  const getPartHeaders = (parts) => {
    return Array.from(
      parts.reduce((keys, obj) => {
        Object.keys(obj).forEach((key) => {
          if (key !== "_id") keys.add(key);
        });
        return keys;
      }, new Set())
    );
  };

  // select single header of selected file
  const checkSingle = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter((item) => item !== value));
    }
  };

  // select all headers of selected file
  const checkAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelected(addHeaders);
    } else {
      setSelected([]);
    }
  };

  // when file is selected/dropped from file dialog
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];

        if (file && file.size > 0) {
          const fileMimeType = file.type.toLowerCase();
          if (fileMimeType !== "text/csv" && fileMimeType !== "application/csv")
            return;

          clearAll();

          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              setFile(file);
              setData(results.data);
              setCurrHeaders(results.meta.fields);

              prevHeaders.forEach((header) => {
                if (!results.meta.fields.includes(header))
                  setMissingHeaders((prev) => [...prev, header]);
              });
            },
          });
        }
      } else clearAll();
    },
    [prevHeaders]
  );

  // react drag n drop
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: { "text/csv": [] },
      onDrop,
      onFileDialogCancel: () => clearAll(),
    });

  // set selected file headers, empty unique values, duplicate unique values
  // set allMatch, additional headers
  useEffect(() => {
    if (currHeaders.includes(prevUniqueHeader)) {
      setEmptyUniqueValues(hasEmptyUniqueValues(data, prevUniqueHeader));
      setDuplicateUniqueValues(
        findDuplicates(data, prevParts, prevUniqueHeader)
      );
      setAllMatch(isAllMatch(data, prevParts, prevUniqueHeader));
      setAddHeaders(
        currHeaders.filter((header) => !prevHeaders.includes(header))
      );
    }
  }, [currHeaders, data, prevHeaders, prevParts, prevUniqueHeader]);

  // check if unique headers in selected file contain empty values
  const hasEmptyUniqueValues = (data, uniqueHeader) => {
    const emptyRows = [];

    data.forEach((item, index) => {
      if (item[uniqueHeader] === "") {
        emptyRows.push(index);
      }
    });

    return emptyRows;
  };

  // find duplicates of unique values in selected file
  const findDuplicates = (data, prevParts, uniqueHeader) => {
    const duplicates = [];
    const seenValues = {};

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const value = item[uniqueHeader];

      const itemFound = prevParts.find(
        (newItem) => newItem[uniqueHeader] === value
      );

      if (!itemFound) {
        if (seenValues[value]) {
          if (!duplicates.includes(seenValues[value])) {
            duplicates.push(seenValues[value]);
          }
          duplicates.push({ row: i, item: value });
        } else {
          seenValues[value] = { row: i, item: value };
        }
      }
    }

    return duplicates;
  };

  // check if every unique values in selected file already found in curren event
  const isAllMatch = (data, prevData, uniqueHeader) => {
    const prevKeys = new Set(prevData.map((item) => item[uniqueHeader]));
    for (let item of data) {
      if (!prevKeys.has(item[uniqueHeader])) return false;
    }
    return true;
  };

  // sort headers according to the order of the original csv
  const sortHeaders = (headers, selected) => {
    const sortedSelected = [...selected];

    sortedSelected.sort((a, b) => {
      return headers.indexOf(a) - headers.indexOf(b);
    });

    return sortedSelected;
  };

  // add addtional headers to prevParts
  const addHeadersToPrevParts = (data, prevData, headers, uniqueHeader) => {
    const result = [];
    for (let item of prevData) {
      const prevUnique = item[uniqueHeader];

      const newItem = data.find((item) => item[uniqueHeader] === prevUnique);

      for (let header of headers) {
        if (newItem) {
          if (newItem.hasOwnProperty(header)) {
            item[header] = newItem[header];
          } else {
            item[header] = "";
          }
        } else {
          item[header] = "";
        }
      }
      result.push(item);
    }

    return result;
  };

  // when remove button is click in drap and drop
  const onRemoveFile = (e) => {
    e.stopPropagation();
    clearAll();
  };

  // clear state except for prevPars, prevHeaders and uniqueHeader
  const clearAll = () => {
    setFile();
    setData([]);
    setCurrHeaders([]);
    setEmptyUniqueValues([]);
    setDuplicateUniqueValues([]);
    setMissingHeaders([]);
    setAllMatch(false);
    setSelected([]);
  };

  // checks if data is safe to upload
  const isValidToUpload = () => {
    return (
      file &&
      data.length > 0 &&
      emptyUniqueValues.length <= 0 &&
      currHeaders.includes(prevUniqueHeader)
    );
  };

  // upload mutation
  const uploadMutation = useMutation(
    async ({ id, data }) => {
      const res = await updateEvent(id, data);
      return res;
    },
    {
      onSuccess: () => {
        toast.success("Updated successfully");
        navigate(`/events/parts/${id}`);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { isLoading, isError } = uploadMutation;

  // when upload btn is clicked
  const uploadHandler = async (data, prevData) => {
    if (!isValidToUpload) return;

    let updatedPrevData;
    const sortedHeaders = sortHeaders(addHeaders, selected);

    // if additional headers are selected, add them to prevParts
    if (selected.length > 0) {
      updatedPrevData = addHeadersToPrevParts(
        data,
        prevData,
        sortedHeaders,
        prevUniqueHeader
      );
    } else updatedPrevData = prevData;

    const result = [];

    // find unique items in selected file
    for (let i = 0; i < data.length; i++) {
      const newItem = data[i];
      const newKey = newItem[prevUniqueHeader];

      let found = false;

      // item in prevParts
      for (let j = 0; j < updatedPrevData.length; j++) {
        const existingItem = updatedPrevData[j];
        const existingKey = existingItem[prevUniqueHeader];

        if (newKey === existingKey) {
          found = true;
          break;
        }
      }

      // new item
      if (!found) {
        const filteredItem = {};

        const headers = prevHeaders.concat(sortedHeaders);

        for (let k = 0; k < headers.length; k++) {
          const header = headers[k];

          if (newItem.hasOwnProperty(header)) {
            filteredItem[header] = newItem[header];
          } else {
            filteredItem[header] = "";
          }
        }

        result.push(filteredItem);
      }
    }

    uploadMutation.mutate({ id, data: updatedPrevData.concat(result) });
  };

  return (
    <MainContainer rightDrawer={rightDrawer}>
      {isGetEventLoading ? (
        <LoadingBar />
      ) : isGetEventError ? (
        <IsError />
      ) : (
        <div className="w-full p-8">
          <PageTitle title={eventName} />
          <div className="flex flex-col gap-6 xl:w-1/2 lg:w-3/4">
            {/* EVENT NAME */}
            <div className="flex flex-col">
              <h1>Event name</h1>
              <textarea
                defaultValue={eventName}
                rows={1}
                disabled
                className="px-4 py-2 italic border rounded-lg resize-none border-zinc-500 bg-blue-50 hover:cursor-not-allowed"
              />
            </div>
            {/* END OF EVENT NAME */}

            {/* DRAG AND DROP */}
            <div className="flex flex-col">
              <h1>
                Select a file
                <span className="ml-1 text-sm text-zinc-500">(Required)</span>
              </h1>
              <div
                {...getRootProps({
                  className:
                    "border-2 border-dashed border-zinc-400 rounded-lg h-36 flex flex-col justify-center items-center gap-4 hover:cursor-pointer",
                })}
              >
                <BsDownload
                  size={40}
                  className="text-zinc-400"
                />
                <input {...getInputProps({ accept: "csv" })} />
                {acceptedFiles.length > 0 && file ? (
                  <div className="flex flex-col items-center justify-center text-sm text-center md:text-base">
                    <p>{file.path}</p>
                    <p
                      onClick={onRemoveFile}
                      className="text-sm font-semibold text-red-500 hover:cursor-pointer hover:underline hover:font-bold"
                    >
                      Remove
                    </p>
                  </div>
                ) : isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    <span className="font-bold">Choose a file</span> or drag it
                    here.
                  </p>
                )}
              </div>
            </div>
            {/* END OF DRAG AND DROP */}

            {/* SELECT HEADERS */}
            <div className="flex flex-col gap-2">
              <h1>
                Select headers
                <span className="ml-1 text-sm text-zinc-500">(Required)</span>
              </h1>
              <div className="flex flex-col gap-2 p-2 border rounded-lg shadow-sm">
                {file && !currHeaders.includes(prevUniqueHeader) ? (
                  <Alert
                    color="failure"
                    additionalContent={
                      <div>
                        <div className="text-sm">
                          <p>
                            This file does not contain the current unique
                            header:{" "}
                            <span className="font-semibold">
                              {prevUniqueHeader}
                            </span>
                            .
                          </p>
                          <p>
                            Add{" "}
                            <span className="font-semibold">
                              {prevUniqueHeader}
                            </span>{" "}
                            to this file and try again.
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <h3 className="font-semibold">
                      Unique header not found in {file.path}
                    </h3>
                  </Alert>
                ) : file && emptyUniqueValues.length > 0 ? (
                  <Alert
                    color="failure"
                    additionalContent={
                      <div className="text-sm">
                        <ul className="mt-1 mb-3 ml-3 list-decimal list-inside">
                          {emptyUniqueValues.map((item, index) => (
                            <li key={"emptyUnique" + index}>Row: {item + 2}</li>
                          ))}
                        </ul>
                        <p>Every unique header must contain value</p>
                      </div>
                    }
                  >
                    <h3 className="font-semibold">
                      Empty value in unique header: {prevUniqueHeader}
                    </h3>
                  </Alert>
                ) : file && duplicateUniqueValues.length > 0 ? (
                  <Alert
                    color="warning"
                    additionalContent={
                      <div className="text-sm">
                        <ul className="mt-1 mb-3 ml-3 list-decimal list-inside">
                          {duplicateUniqueValues.map((item, index) => (
                            <li key={index}>
                              Row: {item.row + 2}, {prevUniqueHeader}:{" "}
                              {item.item}
                            </li>
                          ))}
                        </ul>

                        <p>
                          These rows will be included in the uploaded data if
                          you choose to proceed.
                        </p>
                        <p>
                          However, other duplicates found in future upload will
                          be ignored.
                        </p>
                      </div>
                    }
                  >
                    <h3 className="font-semibold">
                      Warning: Duplicates of unique header: {prevUniqueHeader}{" "}
                      found in {file.path}
                    </h3>
                  </Alert>
                ) : file && missingHeaders.length > 0 ? (
                  <Alert
                    color="warning"
                    additionalContent={
                      <div>
                        <div className="flex flex-col gap-2 text-sm">
                          <ul className="my-1 ml-3 list-disc list-inside">
                            {missingHeaders.map((header, index) => (
                              <li key={index}>{header}</li>
                            ))}
                          </ul>
                          <p>
                            These headers will be added to every new row with
                            empty value.
                          </p>
                          <p>
                            If missing header is{" "}
                            <span className="font-semibold">age</span> and
                            header{" "}
                            <span className="font-semibold">IC number</span> is
                            present in{" "}
                            <span className="font-semibold">{file.path}</span>,
                            age wil be calculated based on{" "}
                            <span className="font-semibold">IC number</span>.
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <h3 className="font-semibold">Warning: Missing headers</h3>
                  </Alert>
                ) : file && currHeaders.length > prevHeaders.length ? (
                  <Alert
                    color="warning"
                    additionalContent={
                      <div>
                        <div className="text-sm ">
                          <ul className="mt-1 ml-3 list-disc list-inside">
                            <li>
                              The selected headers will be included when CSV
                              file is uploaded.
                            </li>
                            <li>
                              The selected headers will also be added to
                              existing data:
                              <ul className="my-2 ml-5 list-decimal list-outside">
                                <li>
                                  If match with unique header is found, these
                                  headers will now contain the same value found
                                  in {file.path}
                                </li>
                                <li>
                                  If no match is found, these headers will
                                  contain empty value
                                </li>
                              </ul>
                            </li>
                            <li className="font-semibold">
                              This is permanent and cannot be undone.
                            </li>
                          </ul>
                        </div>
                      </div>
                    }
                  >
                    <h3 className="font-medium">
                      Warning: New headers found in {file.path}
                    </h3>
                  </Alert>
                ) : file && allMatch ? (
                  <Alert color="warning">
                    <div className="flex flex-col gap-1">
                      <p>
                        <span className="font-semibold">
                          Warning: {eventName}
                        </span>{" "}
                        already contains every row with matching unique header:{" "}
                        <span className="font-semibold">
                          {prevUniqueHeader}
                        </span>{" "}
                        found in{" "}
                        <span className="font-semibold">{file.path}</span>
                      </p>
                      <p>
                        You may still proceed to upload. However, this may cause
                        duplicates with same{" "}
                        <span className="font-semibold">
                          {prevUniqueHeader}
                        </span>{" "}
                        to be created.
                      </p>
                    </div>
                  </Alert>
                ) : currHeaders.length === prevHeaders.length ? (
                  <Alert color="info">
                    <span>
                      <p className="text-sm">
                        All headers match. Click upload to proceed.
                      </p>
                    </span>
                  </Alert>
                ) : (
                  <Alert color="info">
                    <span>
                      <p className="text-sm">
                        Headers will appear once a file is selected.
                      </p>
                    </span>
                  </Alert>
                )}
                <Alert color="info">
                  <span>
                    <p>
                      Unique header is{" "}
                      <span className="font-semibold">{prevUniqueHeader}</span>
                    </p>
                  </span>
                </Alert>

                {/* SELECT ADDTIONAL HEADERS */}
                <div className="flex flex-col gap-1 px-4">
                  {file &&
                    currHeaders.includes(prevUniqueHeader) &&
                    addHeaders.length > 0 &&
                    !emptyUniqueValues && (
                      <>
                        <h1 className="px-4 py-1 my-2 text-sm text-white bg-blue-500 rounded-lg w-fit">
                          {addHeaders.length} New headers
                        </h1>
                        <div className="flex items-center gap-2">
                          <Checkbox
                            id="selectAll"
                            onChange={(e) => checkAll(e, selected)}
                            checked={
                              selected.length > 0 &&
                              selected.length === addHeaders.length
                            }
                          />
                          <label
                            htmlFor="selectAll"
                            className="cursor-pointer hover:text-blue-600 hover:font-semibold"
                          >
                            Select all
                          </label>
                        </div>
                        {currHeaders
                          .filter((header) => !prevHeaders.includes(header))
                          .map((header, index) => (
                            <div
                              className="flex items-center gap-2"
                              key={index}
                            >
                              <Checkbox
                                id={"newHeader" + index}
                                value={header}
                                onChange={checkSingle}
                                checked={selected.includes(header)}
                              />
                              <label
                                htmlFor={"newHeader" + index}
                                className="cursor-pointer hover:font-semibold hover:text-blue-600"
                              >
                                {header}
                              </label>
                            </div>
                          ))}
                      </>
                    )}
                </div>
                {/* END OF SELECT ADDTIONAL HEADERS */}
              </div>
            </div>
            {/* UPLOAD ERROR */}
            {isError && (
              <Alert color="failure">
                <div className="flex flex-col gap-2">
                  <h3 className="font-semibold">
                    Failed to update {eventName}
                  </h3>
                  <p>
                    Something went wrong. Kindly check your internet connection
                    and try again.
                  </p>
                </div>
              </Alert>
            )}
            {/* END OF UPLOAD ERROR */}

            {/* UPLOAD BUTTON */}
            <button
              type="button"
              disabled={!isValidToUpload()}
              onClick={() => uploadHandler(data, prevParts)}
              className={clsx(
                isLoading ? "pointer-events-none" : "cursor-pointer",
                "px-4 py-2 text-white bg-blue-500 rounded-lg w-fit hover:bg-blue-600 disabled:bg-zinc-400 disabled:cursor-not-allowed"
              )}
            >
              {isLoading ? <Spinner /> : "Upload"}
            </button>
            {/* END OF UPLOAD BUTTON */}
          </div>
        </div>
      )}
    </MainContainer>
  );
};

export default UpdatePart;
