import React from "react";
import { useNavigate, NavLink } from "react-router-dom";

// ui
import { styles } from "./styles/SideNav.styles";
import { Spinner } from "flowbite-react";
import logo from "../../public/images/techdome_logo.png";

// icons
import { FiLogOut, FiUser } from "react-icons/fi";
import { BsCalendar3 } from "react-icons/bs";
import { CgUserList } from "react-icons/cg";

// hooks
import useCurrentUser from "../../hooks/useCurrentUser";

export default function SideNav({ rightDrawer }) {
  const getCurrentUser = useCurrentUser();
  const { data: currentUser, isLoading, isError, isAdmin } = getCurrentUser;

  // generate user's initials
  const initials = (name) => {
    if (!name) return "-";
    name = name.split(" ");
    if (name.length < 2) return name[0][0] + name[0][1];
    return name[0][0] + name[1][0];
  };

  const navigate = useNavigate();

  // log out
  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  //class names for navlinks
  const activeStyle = `${styles.navlinkActive} ${
    !rightDrawer.isOpen ? "justify-start " : "justify-center"
  } `;

  const inactiveStyle = `${styles.navlinkInactive} ${
    !rightDrawer.isOpen ? "justify-start " : "justify-center"
  }`;

  let currentUserInfo;

  if (isLoading) {
    currentUserInfo = (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  } else if (isError) {
    currentUserInfo = (
      <div className="flex text-sm text-white">
        <h1>Failed to fetch current user</h1>
      </div>
    );
  } else {
    const name =
      currentUser.role === "admin"
        ? currentUser?.name + " (Admin)"
        : currentUser?.role === "superadmin"
        ? currentUser?.name + " (Superadmin)"
        : currentUser?.name;

    currentUserInfo = (
      <div className="flex">
        <div
          className={` ${!rightDrawer.isOpen ? "w-12 h-12" : "w-full h-12"} ${
            styles.userContainer
          }`}
        >
          <h1 className={styles.initials}>{initials(currentUser?.name)}</h1>
        </div>
        {!rightDrawer.isOpen ? (
          <div className={styles.nameEmailContainer}>
            <h1 className="font-semibold text-white">{name}</h1>
            <p>{currentUser?.email}</p>
          </div>
        ) : null}
      </div>
    );
  }

  let contactNavLink;

  if (!isLoading && !isError) {
    if (isAdmin) {
      contactNavLink = (
        <NavLink
          to="/contacts"
          className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
        >
          <CgUserList className={`text-2xl text-white`} />
          {!rightDrawer.isOpen ? (
            <h1 className="flex flex-col justify-end">Contacts</h1>
          ) : null}
        </NavLink>
      );
    }
  } else {
    contactNavLink = <div></div>;
  }

  return (
    <div
      className={`w-full ${
        !rightDrawer.isOpen ? "p-4" : "p-2"
      } sticky top-0 left-0 transition ease-in`}
    >
      <div
        className={`flex ${
          !rightDrawer.isOpen
            ? "flex-row  gap-2"
            : "flex-col justify-center items-center w-full"
        }`}
      >
        <img
          src={logo}
          alt="Tech Dome Logo"
          className={`${!rightDrawer.isOpen ? "w-6 h-6" : "w-8 h-8"}`}
        />
        <h1
          className={`"tracking-wide text-white/70 uppercase font-rajdhani ${
            !rightDrawer.isOpen ? "block" : "hidden"
          }`}
        >
          Tech Dome Penang
        </h1>
      </div>
      <div>
        <div className="my-16">{currentUserInfo}</div>

        {/* navlinks */}
        <div className="flex flex-col space-y-4">
          <NavLink
            to="/events"
            className={({ isActive }) =>
              isActive ? activeStyle : inactiveStyle
            }
          >
            <BsCalendar3 className="text-2xl text-white" />
            {!rightDrawer.isOpen ? (
              <h1 className="flex flex-col justify-end">Events</h1>
            ) : null}
          </NavLink>

          {contactNavLink}

          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive ? activeStyle : inactiveStyle
            }
          >
            <FiUser className="text-2xl text-white" />
            {!rightDrawer.isOpen ? (
              <h1 className="flex flex-col justify-end">Profile</h1>
            ) : null}
          </NavLink>
          {/* logout button */}
          <div
            onClick={logoutHandler}
            className={` ${
              !rightDrawer.isOpen ? "justify-start" : "justify-center"
            } ${styles.logoutBtnContainer}`}
          >
            <FiLogOut className="text-2xl text-white" />
            {!rightDrawer.isOpen ? (
              <h1 className="flex flex-col justify-end">Logout</h1>
            ) : null}
          </div>
          {/* end of logout button */}
        </div>
        {/* end of navlinks */}
      </div>
    </div>
  );
}
