import { useMutation, useQueryClient } from "react-query";

// ui
import toast from "react-hot-toast";
import DeleteModal from "../modals/DeleteModal";

// api
import { deleteMany } from "../../api/participants";

export default function DeleteMany({ deleteModal, data, clear }) {
  const { event, parts } = data;
  const { setSelectedItem, setDeleteManyData } = clear;

  const queryClient = useQueryClient();
  
  const deleteManyMutation = useMutation(async (array) => {
    const res = await deleteMany(event, array);
    return res;
  });

  const { isLoading, isError, reset } = deleteManyMutation;

  const deleteHandler = (parts) => {
    const array = [];
    parts.map((item) => array.push(item.id.toString()));

    deleteManyMutation.mutate(array, {
      onSuccess: () => {
        queryClient.invalidateQueries("parts");
        setSelectedItem([]);
        setDeleteManyData([]);
        deleteModal.onClose();
        toast.success("Participant deleted.");
      },
    });
  };

  return (
    <DeleteModal
      deleteOne={false}
      deleteModal={deleteModal}
      isLoading={isLoading}
      isError={isError}
      reset={reset}
      onConfirm={() => deleteHandler(parts)}
      data={parts}
    />
  );
}
