import DeleteModal from "../modals/DeleteModal";

// api
import { deleteParticipant } from "../../api/participants";

// packages
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";

export default function DeleteOne({ deleteModal, data }) {
  const { id, name } = data ;
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(async (id) => {
    const res = await deleteParticipant(id);
    return res;
  });

  const { isLoading, isError, reset } = deleteMutation;

  const deleteHandler = (id) => {
    deleteMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries("parts");
        deleteModal.onClose();
        toast.success("Participant deleted.");
      },
    });
  };

  return (
    <DeleteModal
      deleteOne={true}
      deleteModal={deleteModal}
      isLoading={isLoading}
      isError={isError}
      reset={reset}
      onConfirm={() => deleteHandler(id)}
      data={[name]}
    />
  );
}
