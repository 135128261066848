import SideNav from "./navbar/SideNav";
import MobileNav from "./navbar/MobileNav";

const MainContainer = ({ rightDrawer, children }) => {
  return (
    <div className="relative flex flex-col">
      <MobileNav />
      <div className="relative flex w-full min-h-screen">
        <div
          className={`bg-gradient-to-tr from-blue-800 to-purple-500 sticky top-0 hidden md:block ${
            rightDrawer.isOpen ? "w-16" : "w-80"
          }`}
        >
          <SideNav rightDrawer={rightDrawer} />
        </div>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

export default MainContainer;
