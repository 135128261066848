import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// ui
import PartTable from "../../components/participant/PartTable";
import PageTitle from "../../components/PageTitle";
import IsError from "../../components/IsError";
import FetchLoading from "../../components/FetchLoading";
import FetchError from "../../components/FetchError";
import MainContainer from "../../components/MainContainer";
import { AiOutlineClose } from "react-icons/ai";

// packages
import { useQuery } from "react-query";
import Select from "react-select";
import clsx from "clsx";

// api
import { getParts } from "../../api/participants";

// hooks
import useRightDrawer from "../../hooks/useRightDrawer";

const Participants = () => {
  const rightDrawer = useRightDrawer();
  rightDrawer.isOpen = true;

  const { id } = useParams();
  const [eventName, setEventName] = useState(""); // event name
  const [data, setData] = useState([]); // participants in this event
  const [headers, setHeaders] = useState([]); // headers to display in table

  // options for select
  const [headerOptions, setHeaderOptions] = useState([]); // header options for header select
  const [options, setOptions] = useState([]); // value options that matches header for select

  //  filters
  const [selectedKey, setSelectedKey] = useState(null); // selected header
  const [selectedValue, setSelectedValue] = useState(null); // selected value
  const [emptyValue, setEmptyValue] = useState(false); //empty value in value select
  const [ageMin, setAgeMin] = useState(0); // minimum age for age value
  const [ageMax, setAgeMax] = useState(0); // maximum age for age value

  const [filters, setFilters] = useState([]); // selected filters to be displayed in filters box
  const [ageFilter, setAgeFilter] = useState([]); // selected age filter

  const [sortHeader, setSortHeader] = useState({ header: "", order: 0 });
  const [queryParams, setQueryParams] = useState();

  // fetch event that matches the id
  const { isLoading, isError } = useQuery(
    ["parts", queryParams],
    async () => {
      const res = await getParts(id, queryParams);
      return res;
    },
    {
      onSuccess: (data) => {
        const parts = data.parts;

        setEventName(data.event);
        setData(parts);

        const headerOptions = new Set();
        const headerKeys = new Set();

        for (const item of parts) {
          for (const key in item) {
            if (key === "_id") continue;
            headerKeys.add(key);

            if (!filters.some((filter) => Object.keys(filter).includes(key))) {
              if (key !== "age") {
                headerOptions.add(key);
              } else {
                if (ageFilter.length <= 0) {
                  headerOptions.add(key);
                }
              }
            }
          }
        }

        setHeaderOptions(convertSetToHeaderOptions(headerOptions));
        setHeaders(Array.from(headerKeys));

        if (sortHeader.header !== "") {
          setData((prevData) =>
            sortHeaderFn(prevData, sortHeader.header, sortHeader.order)
          );
        }
      },
    }
  );

  // sort data based on sortHeader
  useEffect(() => {
    if (headers.length > 0) {
      if (sortHeader.header === "") {
        setSortHeader({ header: headers[0], order: 0 });
      }

      setData((prevData) =>
        sortHeaderFn(prevData, sortHeader.header, sortHeader.order)
      );
    }
  }, [sortHeader, setData, headers]);

  // sort data based of header functon
  const sortHeaderFn = (data, header, order) => {
    const sortedPeople = [...data];

    sortedPeople.sort((a, b) => {
      const valueA = a[header];
      const valueB = b[header];

      if (order === 0) {
        // Ascending order
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else if (order === 1) {
        // Descending order
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
      }

      return 0;
    });

    return sortedPeople;
  };

  // return a list of unique header options
  const convertSetToHeaderOptions = (set) => {
    return Array.from(set).map((item) => {
      return { value: item, label: item };
    });
  };

  // when header is selected
  const headerSelectOnChange = (header) => {
    setSelectedValue(null);

    if (header) {
      const uniqueOptions = [
        ...new Set(data.map((option) => option[header.value])),
      ];
      const options = uniqueOptions.map((option) => ({
        value: option,
        label: option,
      }));

      setEmptyValue(false);
      setOptions(options);
      setSelectedKey(header.value);
    } else {
      setOptions([]);
      setSelectedKey(null);
    }
  };

  // when a value if selected
  const valueSelectOnChange = (data) => {
    if (data) {
      setSelectedValue(data.value);
      setEmptyValue(false);
    } else setSelectedValue(null);
  };

  // when age min/age max input has value
  const ageOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "ageMin") setAgeMin(value);
    else setAgeMax(value);
  };

  // add filter to filters box
  const addFilter = (selectedKey, selectedValue) => {
    if (selectedKey !== "age" && (!selectedValue || !selectedKey)) {
      setEmptyValue(true);
      return;
    } else if (selectedKey === "key" && (!ageMin || !ageMax)) {
      setEmptyValue(true);
      return;
    }

    setSelectedKey(null);
    setSelectedValue(null);

    const newFilter = { [selectedKey]: selectedValue };

    if (selectedKey === "age") {
      setAgeFilter([{ age_min: ageMin }, { age_max: ageMax }]);
    } else setFilters((prev) => [...prev, newFilter]);
  };

  // set query params
  useEffect(() => {
    const filterQueryParams = filters.map((item) => {
      const key = Object.keys(item)[0];
      return `${key}=${item[key]}`;
    });

    const ageQueryParams = ageFilter.map((item) => {
      const key = Object.keys(item)[0];
      return `${key}=${item[key]}`;
    });

    const queryStringified = filterQueryParams.concat(ageQueryParams).join("&");

    setQueryParams(queryStringified);
  }, [ageFilter, data, filters]);

  // remove filter form filters list
  const removeFilter = (filter) => {
    const newFilters = filters.filter((item) => item !== filter);
    setFilters(newFilters);
  };

  // color options for filters
  const colors = ["blue", "green", "purple"];

  // classnames for header and value select dropdown
  const selectControlStyles = {
    base: "border rounded-lg h-full px-4 w-72 border-zinc-500 bg-white capitalize",
    focus: "border-blue-500 ring-1 ring-blue-500",
    noFocus: "border-zinc-500",
    isError: "border-red-400 ring-1 ring-red-400",
    noOptions: "bg-zinc-200 pointer-events-none italic text-zinc-600",
  };

  const selectMenuStyles = "bg-white border shadow-md rounded-lg mt-1";
  const selectOptionStyles = {
    base: "py-2 px-4",
    focus: "bg-blue-500 text-white",
    noFocus: "bg-white text-zinc-800",
  };

  const selectNoOptionMsgStyles =
    "bg-white border border-zinc-300 rounded-lg py-2 ";

  return (
    <MainContainer rightDrawer={rightDrawer}>
      {isError ? (
        <IsError />
      ) : (
        <div className="w-full p-8 sm:max-w-[calc(100vw-80px)]">
          <PageTitle title={eventName} />
          <div className="flex flex-col gap-4 p-4 border rounded-lg shadow-md border-zinc-300 bg-zinc-50">
            <h1 className="text-2xl font-semibold text-blue-400 font-rajdhani">
              Filters
            </h1>
            <div className="z-30 flex flex-wrap gap-4">
              <Select
                className="basic-single"
                classNames={{
                  control: ({ isFocused }) =>
                    clsx(
                      selectControlStyles.base,
                      isFocused
                        ? selectControlStyles.focus
                        : emptyValue
                        ? selectControlStyles.isError
                        : selectControlStyles.noFocus
                    ),
                  menu: () => selectMenuStyles,
                  option: ({ isFocused }) =>
                    clsx(
                      selectOptionStyles.base,
                      "capitalize",
                      isFocused
                        ? selectOptionStyles.focus
                        : selectOptionStyles.noFocus
                    ),
                  noOptionsMessage: () => selectNoOptionMsgStyles,
                }}
                styles={{
                  input: (base) => ({
                    ...base,
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                }}
                onChange={headerSelectOnChange}
                value={
                  selectedKey
                    ? { value: selectedKey, label: selectedKey }
                    : null
                }
                unstyled={true}
                classNamePrefix="select"
                placeholder="Select header..."
                defaultValue={{ value: null, label: "Select a header..." }}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="headerOptions"
                options={headerOptions}
              />
              {selectedKey === "age" ? (
                <h1 className="px-4 py-2 border rounded-lg border-zinc-500">
                  is between
                </h1>
              ) : (
                <h1 className="px-4 py-2 text-lg border rounded-lg border-zinc-500">
                  is
                </h1>
              )}
              {selectedKey === "age" ? (
                <div className="flex gap-4">
                  <input
                    value={ageMin}
                    name="ageMin"
                    onChange={ageOnChange}
                    className="px-4 border rounded-lg border-zinc-500"
                  />
                  <h1 className="px-4 py-2 border rounded-lg border-zinc-500">
                    and
                  </h1>
                  <input
                    value={ageMax}
                    name="ageMax"
                    onChange={ageOnChange}
                    className="px-4 border rounded-lg border-zinc-500"
                  />
                </div>
              ) : (
                <Select
                  className="basic-single"
                  classNames={{
                    control: ({ isFocused }) =>
                      clsx(
                        selectControlStyles.base,
                        options.length <= 0
                          ? selectControlStyles.noOptions
                          : isFocused
                          ? selectControlStyles.focus
                          : emptyValue
                          ? selectControlStyles.isError
                          : selectControlStyles.noFocus
                      ),
                    menu: () => selectMenuStyles,
                    option: ({ isFocused }) =>
                      clsx(
                        selectOptionStyles.base,
                        isFocused
                          ? selectOptionStyles.focus
                          : selectOptionStyles.noFocus
                      ),
                    noOptionsMessage: () => selectNoOptionMsgStyles,
                  }}
                  styles={{
                    input: (base) => ({
                      ...base,
                      "input:focus": {
                        boxShadow: "none",
                      },
                    }),
                  }}
                  onChange={valueSelectOnChange}
                  value={
                    selectedValue
                      ? { value: selectedValue, label: selectedValue }
                      : null
                  }
                  placeholder="Select value..."
                  defaultValue={{ value: null, label: "Select a value..." }}
                  unstyled={true}
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="options"
                  options={options}
                />
              )}
              <button
                type="button"
                onClick={() => addFilter(selectedKey, selectedValue)}
                className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
              >
                Add Filter
              </button>
            </div>
            {filters.length > 0 || ageFilter.length > 0 ? (
              <div className="flex w-full gap-4 ">
                {filters.map((filter, index) => (
                  <div
                    key={index}
                    className={`flex gap-2 px-4 py-2 bg-${
                      colors[index % colors.length]
                    }-100 border border-zinc-400 rounded-lg w-fit`}
                  >
                    <h1 className="capitalize">{Object.keys(filter)[0]}</h1>
                    <h1>is</h1>
                    <h1>{Object.values(filter)[0]}</h1>
                    <AiOutlineClose
                      onClick={() => removeFilter(filter)}
                      className="flex flex-col justify-center h-full cursor-pointer text-zinc-800"
                    />
                  </div>
                ))}
                {ageFilter.length > 0 ? (
                  <div
                    className={`flex gap-2 px-4 py-2 bg-orange-100 border border-orange-400 rounded-lg w-fit`}
                  >
                    <h1>Age</h1>
                    <h1>is between</h1>
                    <h1>{Object.values(ageFilter[0])}</h1>
                    <h1>and</h1>
                    <h1>{Object.values(ageFilter[1])}</h1>
                    <AiOutlineClose
                      onClick={() => setAgeFilter([])}
                      className="flex flex-col justify-center h-full cursor-pointer text-zinc-800"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {isLoading ? (
            <FetchLoading />
          ) : isError ? (
            <FetchError />
          ) : (
            <PartTable
              eventId={id}
              headers={headers}
              data={data}
              sort={{ sortHeader, setSortHeader, sortHeaderFn }}
            />
          )}
        </div>
      )}
    </MainContainer>
  );
};

// /^([5-9][0-9]|[0-1][0-5])[0-9]{4}-[0-9]{2}-[0-9]{4}$/
export default Participants;
