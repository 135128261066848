import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// ui
import MainContainer from "../../components/MainContainer";
import EventTable from "../../components/event/EventTable";
import EventSearch from "../../components/event/EventSearch";
import PageTitle from "../../components/PageTitle";
import DeleteMany from "../../components/event/DeleteMany";
import FetchLoading from "../../components/FetchLoading";
import FetchError from "../../components/FetchError";
import { styles } from "../../components/event/styles/EventList.styles";

// icons
import { FiTrash2, FiPlus, FiDownload } from "react-icons/fi";

// packages
import { convertArrayToCSV } from "convert-array-to-csv";
import { useQuery } from "react-query";
import queryString from "query-string";

// api
import { checkAuth } from "../../api/users";
import { getEvents } from "../../api/events";

// hooks
import useModal from "../../hooks/useModal";
import useRightDrawer from "../../hooks/useRightDrawer";

export default function Events() {
  const rightDrawer = useRightDrawer();
  const { isAdmin } = checkAuth();

  const deleteModal = useModal();

  const [data, setData] = useState([]); // events to be passed to EventTable component
  const [selected, setSelected] = useState([]); //checked events

  const [search, setSearch] = useState(""); // search input in search bar
  const [sortHeader, setSortHeader] = useState({ header: "", order: 0 }); // sort event based on header

  const queryParams = queryString.stringify({ search });

  const { isLoading, isError, isFetching } = useQuery(
    ["events", search],
    async () => {
      const res = await getEvents(queryParams);
      return res;
    },
    {
      onSuccess: (data) => {
        setData(data);

        if (sortHeader.header !== "") {
          setData((prevData) =>
            sortHeaderFn(prevData, sortHeader.header, sortHeader.order)
          );
        }
      },
    }
  );

  //convert array to csv then download, file name is the name of event
  const downloadHandler = (data) => {
    data.map((item) => {
      const csv = convertArrayToCSV(item.parts);

      const fileName = item.name;
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", fileName + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return "";
    });
  };

  // sort data based on sortHeader
  useEffect(() => {
    if (sortHeader.header === "") {
      setSortHeader({ header: "year", order: 1 });
    }

    setData((prevData) =>
      sortHeaderFn(prevData, sortHeader.header, sortHeader.order)
    );
  }, [sortHeader, setData]);

  // sort data based of header functon
  const sortHeaderFn = (data, header, order) => {
    const sortedPeople = [...data];

    let valueA;
    let valueB;

    sortedPeople.sort((a, b) => {
      if (header === "year") {
        valueA = a["name"].slice(-4);
        valueB = b["name"].slice(-4);
      } else if (header === "parts") {
        valueA = a["parts"].length;
        valueB = b["parts"].length;
      } else if (header === "updatedAt") {
        valueA = new Date(a["updatedAt"]);
        valueB = new Date(b["updatedAt"]);
      } else {
        valueA = a[header];
        valueB = b[header];
      }

      if (order === 0) {
        // Ascending order
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else if (order === 1) {
        // Descending order
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
      }

      return 0;
    });

    return sortedPeople;
  };

  return (
    <MainContainer rightDrawer={rightDrawer}>
      <div className={styles.mainContainer}>
        <div
          className={`${rightDrawer.isOpen ? "flex-grow p-8" : "w-full p-8"}`}
        >
          <PageTitle title={"Events"} />

          {/* Sort, Add, Delete */}
          <div className={styles.sortContainer}>
            <div className={styles.sortInnerContainer}>
              <EventSearch setSearch={setSearch} />
              <div className="flex justify-end w-full gap-2 sm:w-fit">
                {isAdmin ? (
                  <Link to={"/events/add/"}>
                    <button className={styles.addBtn}>
                      <FiPlus className="text-white" />
                    </button>
                  </Link>
                ) : null}
                {selected.length > 0 ? (
                  <>
                    <button
                      className={styles.downloadBtn}
                      onClick={() => downloadHandler(selected)}
                    >
                      <FiDownload className="text-white" />
                    </button>
                    {isAdmin ? (
                      <button
                        className={styles.deleteBtn}
                        onClick={() => deleteModal.onOpen()}
                      >
                        <FiTrash2 className="text-white" />
                      </button>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {/* End of Sort, Add, Delete */}
          {isLoading ? (
            <FetchLoading />
          ) : isError ? (
            <FetchError />
          ) : (
            <EventTable
              data={data}
              isFetching={isFetching}
              select={{ selected, setSelected }}
              rightDrawer={rightDrawer}
              sort={{ sortHeader, setSortHeader }}
            />
          )}
        </div>

        {/* right drawer edit event */}
        <div
          className={`${rightDrawer.isOpen ? "w-[350px]" : "hidden"} ${
            styles.rightDrawer
          } `}
        ></div>
        {/* end of right drawer edit event */}

        <DeleteMany
          setSelected={setSelected}
          deleteModal={deleteModal}
          data={selected}
        />
      </div>
    </MainContainer>
  );
}
