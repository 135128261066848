import { useState } from "react";

// ui
import MainContainer from "../../components/MainContainer";
import SelectRole from "../../components/contact/SelectRole";
import ContactSearch from "../../components/contact/ContactSearch";
import AddContact from "../../components/contact/AddContact";
import DeleteMany from "../../components/contact/DeleteMany";
import PageTitle from "../../components/PageTitle";
import LoadingBar from "../../components/LoadingBar";
import IsError from "../../components/IsError";
import FetchLoading from "../../components/FetchLoading";
import FetchError from "../../components/FetchError";
import { styles } from "./styles/Contacts.styles.";

// icons
import { FiTrash2 } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";

// api
import { getUsers } from "../../api/users";

// packages
import { useQuery } from "react-query";
import queryString from "query-string";

// hooks
import useCurrentUser from "../../hooks/useCurrentUser";
import useModal from "../../hooks/useModal";
import useRightDrawer from "../../hooks/useRightDrawer";
import ContactTable from "../../components/contact/ContactTable";

export default function Contacts() {
  const rightDrawer = useRightDrawer();
  const getCurrentUser = useCurrentUser();
  const deleteModal = useModal();
  const addContactModal = useModal();

  const {
    data: currentUser,
    isLoading: currentUserIsLoading,
    isError: currentUserIsError,
    isSuperAdmin,
  } = getCurrentUser;

  const [selected, setSelected] = useState([]); //for checkbox

  // queries to fetch users from database
  const [query, setQuery] = useState({
    search: "",
    role: "",
  });

  const { search, role } = query;
  const queryParams = queryString.stringify({ search, role });

  // fetch users from database
  const { data, isLoading, isError } = useQuery(
    ["users", queryParams],
    async () => {
      const res = await getUsers(queryParams);
      return res;
    }
  );

  if (currentUserIsLoading) {
    return <LoadingBar />;
  } else if (currentUserIsError) {
    return <IsError />;
  } else {
    return (
      <MainContainer rightDrawer={rightDrawer}>
        <div className={styles.mainContainer}>
          <div
            className={`${rightDrawer.isOpen ? "flex-grow p-8" : "w-full p-8"}`}
          >
            <PageTitle title={"Contacts"} />

            {/* search, add, delete */}
            <div className="flex flex-col gap-6 mt-6 bg-white rounded-t-lg sm:my-6">
              <ContactSearch
                query={query}
                setQuery={setQuery}
              />
              <div className="flex flex-col justify-between gap-4 sm:flex-row sm:gap-0 ">
                <SelectRole
                  query={query}
                  setQuery={setQuery}
                />
                <div className="flex justify-end w-full gap-2 py-1 sm:w-fit md:mt-0">
                  <button
                    onClick={() => addContactModal.onOpen()}
                    className={styles.addBtn}
                  >
                    <IoAdd className={styles.addIcon} />
                  </button>

                  {selected.length > 0 && isSuperAdmin ? (
                    <button
                      className={styles.deleteBtn}
                      onClick={() => deleteModal.onOpen()}
                    >
                      <FiTrash2 className="text-white" />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            {/* end of search, add, delete */}

            {isLoading ? (
              <FetchLoading />
            ) : isError ? (
              <FetchError />
            ) : (
              <ContactTable
                data={data}
                rightDrawer={rightDrawer}
                selected={selected}
                setSelected={setSelected}
                currentUser={currentUser}
                isSuperAdmin={isSuperAdmin}
              />
            )}
            {/* end of table */}
          </div>
          <div
            className={`${rightDrawer.isOpen ? "w-[350px]" : "hidden"} ${
              styles.rightDrawerContainer
            }`}
          >
            <DeleteMany
              deleteModal={deleteModal}
              data={selected}
              setSelected={setSelected}
            />
            <AddContact addContactModal={addContactModal} />
          </div>
        </div>
      </MainContainer>
    );
  }
}
