import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ui
import MainContainer from "../../components/MainContainer";
import PageTitle from "../../components/PageTitle";
import { BsDownload } from "react-icons/bs";
import { Alert, Checkbox, Spinner, Radio } from "flowbite-react";
import { toast } from "react-hot-toast";

// packages
import Papa from "papaparse";
import { useQueryClient, useMutation } from "react-query";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";

// api
import { uploadEvent } from "../../api/events";

// hooks
import useRightDrawer from "../../hooks/useRightDrawer";

const AddEvent = () => {
  const rightDrawer = useRightDrawer();
  rightDrawer.isOpen = false;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [eventName, setEventName] = useState(""); //event name
  const [file, setFile] = useState(); //drag and drop file
  const [data, setData] = useState([]); //data to be uploaded
  const [headers, setHeaders] = useState([]); //headers
  const [selected, setSelected] = useState([]); //selected headers
  const [uniqueHeader, setUniqueHeader] = useState(""); // unique header

  const [duplicateUniqueValues, setDuplicateUniqueValues] = useState([]); // duplicate unique values
  const [emptyUniqueValue, setEmptyUniqueValues] = useState([]); // empty unique values

  const [eventNameInvalid, setEventNameInvalid] = useState(false); // invalid event name

  // event name on change
  const eventNameOnChange = (e) => {
    setEventName(e.target.value);
    setEventNameInvalid(false);
    reset();
  };

  // event name out of focus
  const eventNameOnBlur = (e) => {
    const year = eventName.match(/\b\d{4}\b(?=[^\d]*$)/g);

    if (year && year.length === 1) setEventNameInvalid(false);
    else setEventNameInvalid(true);
  };

  // select header single checkbox
  const headerCheckSingle = (event) => {
    const value = event.target.value;

    if (event.target.checked) {
      setSelected([...selected, value]);
    } else {
      if (value === uniqueHeader) {
        setDuplicateUniqueValues([]);
        setEmptyUniqueValues([]);
        setUniqueHeader("");
      }
      setSelected(selected.filter((item) => item !== value));
    }
  };

  // select header check all checkbox
  const headerCheckAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelected(headers);
    } else {
      setSelected([]);
      setUniqueHeader("");
    }
  };

  // when file is dropped/selected in drag and drop
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      console.log(file)

      if (file && file.size > 0) {
        const fileMimeType = file.type.toLowerCase();

        // check if file is csv
        if (fileMimeType !== "text/csv" && fileMimeType !== "application/csv")
          return;

        clearAll();
        setFile(file);

        // parse file and set data and headers
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setData(results.data);
            setHeaders(results.meta.fields);
            setSelected(results.meta.fields);
          },
        });
      }
    } else {
      clearAll();
    }
  }, []);

  // react drag and drop
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: { "text/csv": [] },
      onDrop,
      onFileDialogCancel: () => clearAll(),
    });

  useEffect(() => {
    if (data.length > 0 && uniqueHeader !== "") {
      setDuplicateUniqueValues(findDuplicates(data, uniqueHeader));
      setEmptyUniqueValues(hasEmptyUniqueValues(data, uniqueHeader));
    }
  }, [data, uniqueHeader]);

  // check if unique headers in selected file contain empty values
  const hasEmptyUniqueValues = (data, uniqueHeader) => {
    const emptyRows = [];

    data.forEach((item, index) => {
      if (item[uniqueHeader] === "") {
        emptyRows.push(index);
      }
    });

    return emptyRows;
  };

  // find duplicates of unique values in selected file
  const findDuplicates = (data, uniqueHeader) => {
    const duplicates = [];
    const seenValues = {};

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const value = item[uniqueHeader];

      if (seenValues[value]) {
        if (!duplicates.includes(seenValues[value])) {
          duplicates.push(seenValues[value]);
        }
        duplicates.push({ row: i, item: value });
      } else {
        seenValues[value] = { row: i, item: value };
      }
    }

    return duplicates;
  };

  // when remove btn is clicked in drag and drop
  const onRemoveFile = (e) => {
    e.stopPropagation();
    clearAll();
  };

  // when unique header is selected
  const uniqueHeaderOnSelect = (e) => {
    setUniqueHeader(e.target.value);
  };

  // clear all input states except for event name
  const clearAll = () => {
    setFile();
    setData([]);
    setHeaders([]);
    setSelected([]);
    setUniqueHeader("");
    setDuplicateUniqueValues([]);
    setEmptyUniqueValues([]);
  };

  // checks if required inputs contain value
  const isValidToUpload = () => {
    return (
      !eventNameInvalid &&
      file &&
      data.length > 0 &&
      selected.length > 0 &&
      uniqueHeader.length > 0 &&
      emptyUniqueValue.length <= 0 &&
      selected.includes(uniqueHeader)
    );
  };

  // sort headers according to the order of the original csv
  const sortHeaders = (headers, selected) => {
    const sortedSelected = [...selected];

    sortedSelected.sort((a, b) => {
      return headers.indexOf(a) - headers.indexOf(b);
    });

    return sortedSelected;
  };

  // upload event mutation
  const uploadMutation = useMutation(
    async ({ eventName, data, uniqueHeader }) => {
      const res = await uploadEvent(eventName, data, uniqueHeader);
      return res;
    }
  );

  const { isLoading, isError, error, reset } = uploadMutation;

  // when upload button is clicked
  const uploadHandler = (data, selected, headers) => {
    if (!isValidToUpload) return;

    const sortedHeaders = sortHeaders(headers, selected);
    const filteredData = [];

    // make a list with only columns that match selected headers
    data.map((item) => {
      const filteredItem = {};

      sortedHeaders.forEach((header) => {
        if (item.hasOwnProperty(header)) {
          filteredItem[header] = item[header];
        }
      });

      filteredData.push(filteredItem);
      return filteredItem;
    });

    const safeEventName = eventName.trim();

    uploadMutation.mutate(
      { eventName: safeEventName, data: filteredData, uniqueHeader },
      {
        onSuccess: (data) => {
          toast.success("Uploaded successfully!");
          queryClient.invalidateQueries("events");
          navigate(`/events/parts/${data._id}`);
        },
      }
    );
  };

  return (
    <MainContainer rightDrawer={rightDrawer}>
      <div className="w-full p-8">
        <PageTitle title="Add Event" />
        <div className="flex flex-col gap-6 xl:w-1/2 lg:w-3/4">
          {/* EVENT NAME */}
          <div className="flex flex-col">
            <h1>
              Event name
              <span className="ml-1 text-sm text-zinc-500">(Required)</span>
            </h1>
            <input
              onChange={eventNameOnChange}
              onBlur={eventNameOnBlur}
              value={eventName}
              autoFocus={true}
              className={clsx(
                eventNameInvalid ||
                  (isError && error.message === "Event name already taken")
                  ? "border-red-500"
                  : "border-zinc-500",
                "px-4 py-2 border rounded-lg resize-none  focus:outline-blue-500"
              )}
            />
            <h1
              className={clsx(
                eventNameInvalid ? "block" : "hidden",
                "text-sm text-red-500"
              )}
            >
              Event name must contain at least one word, a space and ends with 4
              digits. (eg. Event {new Date().getFullYear()})
            </h1>
            <h1
              className={clsx(
                isError && error.message === "Event name already taken"
                  ? "block"
                  : "hidden",
                "text-sm text-red-500"
              )}
            >
              Event name is already taken
            </h1>
          </div>
          {/* END OF EVENT NAME */}

          {/* SELECT FILE DRAG AND DROP */}
          <div className="flex flex-col">
            <h1>
              Select a file
              <span className="ml-1 text-sm text-zinc-500">(Required)</span>
            </h1>
            <div
              {...getRootProps({
                className:
                  "border-2 border-dashed border-zinc-400 rounded-lg h-36 flex flex-col justify-center items-center gap-4 hover:cursor-pointer",
              })}
            >
              <BsDownload
                size={40}
                className="text-zinc-400"
              />
              <input {...getInputProps({ accept: "csv" })} />
              {acceptedFiles.length > 0 && file ? (
                <div className="flex flex-col items-center justify-center text-sm text-center md:text-base">
                  <p>{file.path}</p>
                  <p
                    onClick={onRemoveFile}
                    className="text-sm font-semibold text-red-500 hover:cursor-pointer hover:underline hover:font-bold"
                  >
                    Remove
                  </p>
                </div>
              ) : isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>
                  <span className="font-bold">Choose a file</span> or drag it
                  here.
                </p>
              )}
            </div>
          </div>
          {/* END OF SELECT FILE DRAG AND DROP */}

          {/* SELECT HEADERS */}
          <div className="flex flex-col gap-2">
            <h1>
              Select headers
              <span className="ml-1 text-sm text-zinc-500">(Required)</span>
            </h1>
            <div className="flex flex-col gap-4 p-2 border rounded-lg shadow-sm">
              {/* NO FILE IS SELECTED */}
              {!file ? (
                <Alert color="info">
                  <span>
                    <p className="text-sm">
                      Headers will appear once a file is selected.
                    </p>
                  </span>
                </Alert>
              ) : (
                <Alert color="info">
                  {/* FILE IS SELECTED */}
                  <div className="flex flex-col gap-2">
                    <p className="text-sm">
                      Only the selected headers will be included when CSV file
                      is uploaded.
                    </p>
                    <p>
                      If header that contains{" "}
                      <span className="font-semibold">IC</span> is found, age
                      will be calculated based on the first two digits from{" "}
                      <span className="font-semibold">IC</span> and added to the
                      data.
                    </p>
                    <p className="text-sm font-medium">
                      This is permanent and cannot be undone.
                    </p>
                  </div>
                </Alert>
              )}

              {/* SELECT HEADERS CHECKBOX */}
              {file && (
                <div className="flex flex-col gap-1 px-4">
                  <div className="flex items-center gap-2 mb-4">
                    <Checkbox
                      id="selectAll"
                      onChange={(e) => headerCheckAll(e, selected)}
                      checked={
                        selected.length > 0 &&
                        selected.length === headers.length
                      }
                    />
                    <label
                      htmlFor="selectAll"
                      className="font-semibold cursor-pointer hover:text-blue-600"
                    >
                      Select all
                    </label>
                  </div>
                  {headers.map((header, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2"
                    >
                      <Checkbox
                        id={"header" + index}
                        value={header}
                        onChange={headerCheckSingle}
                        checked={selected.includes(header)}
                        // type="checkbox"
                      />
                      <label
                        htmlFor={"header" + index}
                        className="cursor-pointer hover:font-semibold hover:text-blue-600"
                      >
                        {header}
                      </label>
                    </div>
                  ))}
                </div>
              )}
              {/* END OF SELECT HEADERS CHECKBOX */}
            </div>
          </div>
          {/* END OF SELECT HEADER */}

          {/* SELECT UNIQUE HEADER */}
          {selected.length > 0 && (
            <div className="flex flex-col gap-2">
              <h1>
                Select a unique header
                <span className="ml-1 text-sm text-zinc-500">(Required)</span>
              </h1>
              <div className="flex flex-col gap-2 p-4 border rounded-lg">
                <Alert color="info">
                  <div className="flex flex-col gap-2">
                    <p className="text-sm">
                      Select one header that is{" "}
                      <span className="font-semibold">unique</span> (eg. IC
                      number) to every person/item.
                    </p>
                    <p>
                      This will serve as an identifier to every person/item to
                      prevent duplicate data if you wish to add more rows to
                      this event in the future.
                    </p>
                    <p className="text-sm font-medium">
                      This is permanent and cannot be undone.
                    </p>
                  </div>
                </Alert>
                {selected.map((option, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2"
                  >
                    <Radio
                      id={"uniqueHeader" + index}
                      name="uniqueHeader"
                      value={option}
                      checked={uniqueHeader === option}
                      onChange={uniqueHeaderOnSelect}
                    />
                    <label
                      htmlFor={"uniqueHeader" + index}
                      className="hover:text-blue-500 hover:font-semibold hover:cursor-pointer"
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* END OF SELECT UNIQUE HEADER */}

          {isError && (
            <Alert color="failure">
              <span>
                <p>
                  <span className="font-medium">Failed to upload event.</span>
                  <br />
                  {error.message}
                </p>
              </span>
            </Alert>
          )}
          {emptyUniqueValue.length > 0 ? (
            <Alert
              color="failure"
              additionalContent={
                <div>
                  <div className="text-sm">
                    <ul className="mt-1 mb-3 ml-3 list-decimal list-inside">
                      {emptyUniqueValue.map((item, index) => (
                        <li key={"emptyUnique" + index}>Row: {item + 2}</li>
                      ))}
                    </ul>
                    <p>Every unique header must contain value</p>
                  </div>
                </div>
              }
            >
              <h3 className="font-semibold">
                Empty value in unique header: {uniqueHeader}
              </h3>
            </Alert>
          ) : duplicateUniqueValues.length > 0 ? (
            <Alert
              color="warning"
              additionalContent={
                <div>
                  <div className="text-sm">
                    <ul className="mt-1 mb-3 ml-3 list-decimal list-inside">
                      {duplicateUniqueValues.map((item, index) => (
                        <li key={"duplicate unique" + index}>
                          Row: {item.row + 2}, {uniqueHeader}: {item.item}
                        </li>
                      ))}
                    </ul>

                    <p>
                      These rows will be included in the uploaded data if you
                      choose to proceed.
                    </p>
                    <p>
                      However, other duplicates found in future upload will be
                      ignored.
                    </p>
                  </div>
                </div>
              }
            >
              <h3 className="font-semibold">
                Warning: Duplicates of unique header: {uniqueHeader} found in{" "}
                {file.path}
              </h3>
            </Alert>
          ) : null}
          {/* UPLOAD BUTTON */}
          <button
            type="button"
            disabled={!isValidToUpload()}
            onClick={() => uploadHandler(data, selected, headers)}
            className={clsx(
              isLoading ? "pointer-events-none" : "cursor-pointer",
              "px-4 py-2 text-white bg-blue-500 rounded-lg w-fit hover:bg-blue-600 disabled:bg-zinc-400 disabled:cursor-not-allowed"
            )}
          >
            {isLoading ? <Spinner /> : "Upload"}
          </button>
          {/* END OF UPLOAD BUTTON */}
        </div>
      </div>
    </MainContainer>
  );
};

export default AddEvent;
