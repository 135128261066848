import { useEffect, useState, useCallback } from "react";
import { BsSearch } from "react-icons/bs";
import { styles } from "./styles/ContactSearch.styles";

export default function ContactSearch({ query, setQuery }) {
  const [input, setInput] = useState("");

  const searchSubmit = (e) => {
    e.preventDefault();
    setQuery({ ...query, search: input, role: "" });
  };

  const reset = useCallback(() => {
    setInput("");
    setQuery({
      search: "",
      role: "",
    });
  }, [setQuery]);

  useEffect(() => {
    if (input.length <= 0) {
      reset();
    }
  }, [input.length, reset]);

  return (
    <div className={styles.mainContainer}>
      <form
        onSubmit={searchSubmit}
        className={styles.form}
      >
        <input
          type="text"
          onChange={(e) => setInput(e.target.value)}
          value={input}
          className={styles.searchInput}
          placeholder="Search"
        />
        {input.length > 0 && (
          <button
            type="button"
            onClick={reset}
            className={styles.clearBtn}
          >
            Clear
          </button>
        )}
        <button
          type="submit"
          className="mx-4 "
        >
          <BsSearch />
        </button>
      </form>
    </div>
  );
}
