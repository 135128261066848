import { useState } from "react";
import { Link } from "react-router-dom";

// ui
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import no_result from "../../public/images/spaceguy.gif";
import { styles } from "./styles/EventTable.styles";
import { Checkbox } from "flowbite-react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import DeleteOne from "./DeleteOne";
import EditEventModal from "./EditEventModal";

// packages
import clsx from "clsx";

// hooks
import useModal from "../../hooks/useModal";
import useCurrentUser from "../../hooks/useCurrentUser";

const EventTable = ({ data, isFetching, select, rightDrawer, sort }) => {
  const { isAdmin } = useCurrentUser();

  const deleteModal = useModal();
  const editModal = useModal();

  const { selected, setSelected } = select;
  const { sortHeader, setSortHeader } = sort;

  // edit event
  const [editItem, setEditItem] = useState({}); // editing item data
  const [editedRow, setEditedRow] = useState(""); // latest row that's been edited

  // delete participant
  const [deleteOneData, setDeleteOneData] = useState({}); // delete one part data

  //when checkbox is checked
  const selectOnChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "allSelect") {
        setSelected([]);
        data.map((event) =>
          setSelected((selected) => [
            ...selected,
            { id: event._id, parts: event.parts, name: event.name },
          ])
        );
      } else {
        setSelected([
          ...selected,
          { id: data._id, parts: data.parts, name: data.name },
        ]);
      }
    } else {
      if (name === "allSelect") setSelected([]);
      else {
        let temp = selected.filter((event) => event.id !== data._id);
        setSelected(temp);
      }
    }
  };

  // when header is clicked
  const sortByHeader = (header) => {
    if (sortHeader.header === header) {
      if (sortHeader.order === 0) setSortHeader({ header: header, order: 1 });
      else setSortHeader({ header: header, order: 0 });
    } else setSortHeader({ header: header, order: 0 });
  };

  const editBtnOnClick = (id, name) => {
    setEditItem({ id, name });
    editModal.onOpen();
  };

  const deleteBtnOnClick = (id, name) => {
    setDeleteOneData({ id, name });
    deleteModal.onOpen();
  };

  // headers
  const headers = [
    { label: "event", value: "name" },
    { label: "year", value: "year" },
    { label: "last updated", value: "updatedAt" },
    { label: "upload by", value: "uploadBy" },
    { label: "participants", value: "parts" },
  ];

  return (
    <div>
      {/* event length and selected event */}
      <div className="flex gap-4 mb-2">
        <h1>Showing {data.length} result</h1>
        <h1 className="italic font-medium text-blue-600">
          {selected.length > 0 ? `${selected.length} selected` : null}
        </h1>
      </div>
      {/* end of event length and selected event */}

      {/* event table */}
      {!isFetching && data.length <= 0 ? (
        // no event found image
        <div className={styles.noResult}>
          <img
            src={no_result}
            alt=""
            className="h-60"
          />
          <h1 className="text-xl text-blue-500">No result. </h1>
          <p>Maybe try searching with different keyword instead?</p>
        </div>
      ) : (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.tHead}>
              <tr>
                {/* INDEX */}
                <th
                  scope="col"
                  className="py-3 pl-4"
                />

                {/* EDIT AND DELETE */}
                {isAdmin && (
                  <th
                    scope="col"
                    className="px-2 py-3"
                  ></th>
                )}

                {/* CHECKBOX */}
                <th
                  scope="col"
                  className="px-4 py-3"
                >
                  <Checkbox
                    type="checkbox"
                    name="allSelect"
                    checked={selected.length === data.length}
                    onChange={(e) => selectOnChange(e, data)}
                  />
                </th>

                {/* HEADERS */}
                {headers.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                  >
                    <div
                      onClick={() => sortByHeader(header.value)}
                      className={clsx(
                        "flex items-center gap-2 hover:cursor-pointer w-fit px-2 py-1 rounded-lg hover:bg-white/70 hover:text-blue-500 select-none",
                        sortHeader.header === header.value
                          ? " bg-white/90  text-blue-500"
                          : "text-white"
                      )}
                    >
                      {header.label}
                      {sortHeader.header === header.value &&
                      sortHeader.order === 0 ? (
                        <FaSortUp />
                      ) : sortHeader.header === header.value &&
                        sortHeader.order === 1 ? (
                        <FaSortDown />
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                ))}
                {/* END OF HEADERS */}
              </tr>
            </thead>
            <tbody>
              {data.map((event, index) => {
                return (
                  //if event is selected/checked, bg color is darker than the non-selected rows
                  <tr
                    className={clsx(
                      "border-b",
                      selected.some((item) => item.id === event._id)
                        ? "bg-blue-100 hover:bg-blue-200"
                        : "hover:bg-zinc-50 bg-white",
                      editedRow === event._id
                        ? "bg-blue-400 transition duration-700 animate-pulse ease text-black"
                        : "bg-white hover:bg-gray-50"
                    )}
                    key={event._id}
                  >
                    <td className="py-3 pl-4">{index + 1}</td>
                    {/* EDIT AND DELETE BUTTONS */}
                    {isAdmin && (
                      <td className="w-12 px-2 py-4 border-r border-zinc-100">
                        <div className="flex justify-center gap-4">
                          <AiOutlineEdit
                            className="hover:text-blue-500 hover:cursor-pointer"
                            onClick={() =>
                              editBtnOnClick(event._id, event.name)
                            }
                          />
                          <AiOutlineDelete
                            onClick={() =>
                              deleteBtnOnClick(event._id, event.name)
                            }
                            className="hover:text-red-500 hover:cursor-pointer"
                          />
                        </div>
                      </td>
                    )}
                    {/* END OF EDIT AND DELETE BUTTONS */}
                    <td className="px-4 py-4 ">
                      <Checkbox
                        type="checkbox"
                        name="singleSelect"
                        checked={selected.some((e) => e?.id === event._id)}
                        onChange={(e) => selectOnChange(e, event)}
                      />
                    </td>
                    <th
                      scope="row"
                      className={styles.th}
                    >
                      <Link to={`/events/parts/${event._id}`}>
                        {event.name}
                      </Link>
                    </th>
                    <td className="px-2 py-4 md:break-all">
                      {event.name.slice(-4)}
                    </td>
                    <td className="px-2 py-4 md:break-all">
                      {event.createdAt.slice(0, 10)}
                    </td>
                    <td className="px-2 py-4 md:break-all">{event.uploadBy}</td>
                    <td className="px-2 py-4 md:break-all">
                      {event.parts.length}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <DeleteOne
        deleteModal={deleteModal}
        rightDrawer={rightDrawer}
        data={deleteOneData}
      />
      <EditEventModal
        editModal={editModal}
        data={editItem}
        setEditedRow={setEditedRow}
      />
    </div>
  );
};

export default EventTable;
