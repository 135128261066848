import { useEffect, useState } from "react";

import { Modal, Button, Spinner, Alert } from "flowbite-react";
import { toast } from "react-hot-toast";

import { editEvent } from "../../api/events";
import { useMutation, useQueryClient } from "react-query";
import clsx from "clsx";

const EditEventModal = ({ editModal, data, setEditedRow }) => {
  const { id, name } = data;

  const queryClient = useQueryClient();
  const [eventName, setEventName] = useState("");
  const [submitError, setSubmitError] = useState("");

  const [eventNameInvalid, setEventNameInvalid] = useState(false); // invalid event name

  // set event name input to be the name of the event
  useEffect(() => {
    setEventName(name);
  }, [name]);

  //   event name onChange
  const eventNameOnChange = (e) => {
    reset();
    setEventNameInvalid(false);
    setEventName(e.target.value);
  };

  const editMutation = useMutation(async ({ id, name }) => {
    const res = await editEvent(id, name);
    return res;
  });

  const { isLoading, isError, reset } = editMutation;

  // event name edit submit handler
  const editSubmit = (id, name) => {
    const year = eventName.match(/\b\d{4}\b(?=[^\d]*$)/g);

    if (!year || year.length !== 1) {
      setEventNameInvalid(true);
      return;
    }

    editMutation.mutate(
      { id, name },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("events");
          setEditedRow(id);
          setTimeout(() => {
            setEditedRow("");
          }, 1000);
          editModal.onClose();
          toast.success("Event name changed.");
        },
        onError: (error) => {
          setSubmitError(error.message);
        },
      }
    );
  };

  //   close modal
  const onClose = () => {
    reset();
    editModal.onClose();
  };

  return (
    <Modal
      show={editModal.isOpen}
      size="lg"
      popup
      onClose={onClose}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <h3 className="mb-2 text-lg font-semibold text-blue-500">{name}</h3>
          {isError && (
            <Alert color="failure">
              <div className="flex flex-col">
                <p className="font-medium">Failed to edit {name}</p>
                <p>{submitError}</p>
              </div>
            </Alert>
          )}
          <div className="flex flex-col">
            <h3 className="mb-1 text-sm text-zinc-700">Event name</h3>
            <input
              value={eventName}
              onChange={eventNameOnChange}
              className={clsx(
                "px-2 py-2 border rounded-lg  focus:border-blue-500 focus:outline-blue-500",
                eventNameInvalid ? "border-red-500" : "border-zinc-500"
              )}
            />
            <h1
              className={clsx(
                eventNameInvalid ? "block mt-1" : "hidden",
                "text-sm text-red-500"
              )}
            >
              Event name must contain at least one word, a space and ends with 4
              digits. (eg. Event {new Date().getFullYear()})
            </h1>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="flex justify-center gap-2">
            <Button onClick={() => editSubmit(id, eventName)}>Save</Button>
            <Button
              color="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditEventModal;
