export const styles = {
  mainContainer: "flex w-full min-h-screen",
  //sort, add, delete
  sortContainer: "py-6 space-y-6 bg-white rounded-t-lg",
  sortInnerContainer: "flex items-center sm:justify-between sm:flex-row flex-col gap-4 sm:gap-0",
  addBtn:
    "px-3 py-3 text-center bg-blue-500 rounded-full shadow-md hover:bg-blue-600",
  addIcon: "text-white",
  downloadBtn:
    "px-3 py-3 bg-purple-500 rounded-full shadow-md hover:bg-purple-600",
  deleteBtn: "px-3 py-3 bg-red-500 rounded-full shadow-md hover:bg-red-600",
 
  //right drawer for edit event
  rightDrawer: "bg-gradient-to-br from-[#3f51b5]  to-purple-500 shadow-lg",
};
