import { Oval } from "react-loader-spinner";
const FetchLoading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-72">
      <Oval
        height={60}
        width={60}
        color="#3b82f6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#e7e5e4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default FetchLoading;
