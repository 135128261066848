import { useState } from "react";

// ui
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import DeleteOne from "./DeleteOne";
import { Checkbox } from "flowbite-react";
import { styles } from "./styles/ContactTable.styles";

// hooks
import useModal from "../../hooks/useModal";
import EditContactModal from "./EditContactModal";

const ContactTable = ({
  data,
  rightDrawer,
  selected,
  setSelected,
  currentUser,
  isSuperAdmin,
}) => {
  const deleteModal = useModal();
  const editModal = useModal();

  const [deleteUserData, setDeleteUserData] = useState({});
  const [editUserData, setEditUserData] = useState({});

  //when a checkbox is checked
  const selectOnChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "allSelect") {
        setSelected([]);
        data
          .filter((item) => item.role !== "superadmin")
          .map((item) =>
            setSelected((selected) => [
              ...selected,
              { id: item._id, name: item.name },
            ])
          );
      } else {
        setSelected([...selected, { id: data._id, name: data.name }]);
      }
    } else {
      if (name === "allSelect") setSelected([]);
      else {
        let temp = selected.filter((item) => item.id !== data._id);
        setSelected(temp);
      }
    }
  };

  // returns true if allSelect checkbox should be checked
  const allSelectChecked = (data, selected) => {
    const nonSuper = [];
    data.forEach((item) => {
      if (item.role !== "superadmin") nonSuper.push(item);
    });

    return (
      nonSuper.length === selected.length &&
      selected.every((item) => item.role !== "superadmin")
    );
  };

  // user's name in table
  const isCurrentUserName = (id, currentUserId, name) => {
    if (id === currentUserId) return name + " (You)";
    else return name;
  };

  const editBtnOnClick = (id, name, role) => {
    setEditUserData({ id, name, role });
    editModal.onOpen();
  };
  const deleteBtnOnClick = (id, name) => {
    setDeleteUserData({ id, name });
    deleteModal.onOpen();
  };

  // table headers
  const headers = ["name", "username", "email", "role", "last login"];


  return (
    <div>
      <div className="flex mb-2">
        <h1>Showing {data.length} result</h1>
        <h1 className="mx-4 italic font-medium text-blue-600">
          {selected.length > 0 ? `${selected.length} selected` : null}
        </h1>
      </div>

      {/* table */}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.tHead}>
            <tr>
              {/* INDEX */}
              <th
                scope="col"
                className="py-3 pl-4"
              />

              {/* EDIT AND DELETE */}
              {isSuperAdmin && (
                <th
                  scope="col"
                  className="px-2 py-3"
                ></th>
              )}

              {/* CHECKBOX */}
              {isSuperAdmin && (
                <th
                  scope="col"
                  className="px-6 py-3 "
                >
                  <Checkbox
                    type="checkbox"
                    name="allSelect"
                    checked={allSelectChecked(data, selected)}
                    onChange={(e) => selectOnChange(e, data)}
                  />
                </th>
              )}

              {/* HEADERS */}
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((person, index) => {
              return (
                <tr
                  key={person._id}
                  className="bg-white border-b hover:bg-gray-100"
                >
                  {/* INDEX */}
                  <td className="py-3 pl-4">{index + 1}</td>

                  {/* EDIT AND DELETE BUTTONS */}
                  {isSuperAdmin && (
                    <td className="w-12 px-2 py-4 border-r border-zinc-100">
                      {person.role !== "superadmin" && (
                        <div className="flex justify-center gap-4">
                          <AiOutlineEdit
                            className="hover:text-blue-500 hover:cursor-pointer"
                            onClick={() =>
                              editBtnOnClick(
                                person._id,
                                person.name,
                                person.role
                              )
                            }
                          />
                          <AiOutlineDelete
                            onClick={() =>
                              deleteBtnOnClick(person._id, person.name)
                            }
                            className="hover:text-red-500 hover:cursor-pointer"
                          />
                        </div>
                      )}
                    </td>
                  )}
                  {/* END OF EDIT AND DELETE BUTTONS */}

                  {/* CHECKBOX */}
                  {isSuperAdmin && (
                    <td className="px-6 py-4">
                      {person.role !== "superadmin" && (
                        <Checkbox
                          type="checkbox"
                          name="singleSelect"
                          checked={selected.some((e) => e?.id === person._id)}
                          onChange={(e) => selectOnChange(e, person)}
                        />
                      )}
                    </td>
                  )}

                  {/* NAME */}
                  <th
                    scope="row"
                    className={styles.th}
                  >
                    {isCurrentUserName(
                      person._id,
                      currentUser._id,
                      person.name
                    )}
                  </th>

                  {/* USERNAME */}
                  <td className="px-6 py-4 md:break-all">{person.username}</td>

                  {/* EMAIL */}
                  <td className="px-6 py-4 md:break-all">{person.email}</td>

                  {/* ROLE */}
                  <td
                    className={`px-6 py-4 font-semibold md:break-all ${
                      person.role === "admin"
                        ? "text-blue-500 "
                        : person.role === "superadmin"
                        ? "text-red-500 font-bold"
                        : "text-green-400"
                    }`}
                  >
                    {person.role === "admin"
                      ? "Admin"
                      : person.role === "superadmin"
                      ? "Super Admin"
                      : "Non-Admin"}
                  </td>

                  {/* LAST LOGIN */}
                  <td className="px-6 py-4 md:break-all">
                    {person.date.toString().slice(0, 10)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DeleteOne
        deleteModal={deleteModal}
        data={deleteUserData}
        rightDrawer={rightDrawer}
      />
      <EditContactModal
        editModal={editModal}
        data={editUserData}
      />
    </div>
  );
};

export default ContactTable;
