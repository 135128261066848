import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import moment from "moment/moment";

function useIsLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const now = moment().valueOf();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const user = jwt_decode(token);
      if (user && now < moment.unix(user.exp)) setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [now]);

  return isLoggedIn;
}

export default useIsLoggedIn;
