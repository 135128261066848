import { useEffect, useState } from "react";

// ui
import LoadingBar from "../../components/LoadingBar";
import { Spinner } from "flowbite-react";
import IsError from "../../components/IsError";
import toast from "react-hot-toast";

// styles
import { styles } from "./styles/Profile.style";

// apis
import { updateUser, updatePassword } from "../../api/users";

// packages
import { useMutation, useQueryClient } from "react-query";

// hooks
import useRightDrawer from "../../hooks/useRightDrawer";
import useCurrentUser from "../../hooks/useCurrentUser";
import PageTitle from "../../components/PageTitle";
import MainContainer from "../../components/MainContainer";

export default function Profile() {
  const queryClient = useQueryClient();
  const rightDrawer = useRightDrawer();
  const getCurrentUser = useCurrentUser();

  const { data: currentUser, isLoading, isError } = getCurrentUser;

  //name and email
  const [profile, setProfile] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
  });

  const { name, username, email, password } = profile;

  // once current user is fetched, setCurrentUser
  useEffect(() => {
    if (!isLoading && !isError) {
      setProfile((prev) => ({
        ...prev,
        name: currentUser?.name,
        username: currentUser?.username,
        email: currentUser?.email,
      }));
    }
  }, [isLoading, currentUser, isError]);

  //onChange for name and email
  const onChangeHandler = (e) => {
    setProfile((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    updateProfileReset();
  };

  // check if name is valid
  const validName = (name) => {
    const pattern = /^[a-zA-Z0-9]+([ \t]+[a-zA-Z0-9]+)*$/;
    return pattern.test(name) && name.trim().length >= 3;
  };

  // check if email is valid
  const validEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // useMutation for updating name and email
  const updateProfileMutation = useMutation(
    async ({ name, email, password }) => {
      const res = await updateUser({ name, email, password });
      return res;
    }
  );

  const {
    isLoading: updateProfileLoading,
    isError: updateProfileIsError,
    error: updateProfileError,
    reset: updateProfileReset,
  } = updateProfileMutation;

  // submit handler to update name and email
  const updateProfileSubmit = async (name, email, password) => {
    updateProfileMutation.mutate(
      { name, email, password },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("currentUser");
          toast.success("Profile updated");
          setProfile((prev) => ({ ...prev, password: "" }));
        },
      }
    );
  };

  //useState for old password and new password
  const [userPassword, setUserPassword] = useState({
    oldPass: "",
    newPass: "",
  });

  const { oldPass, newPass } = userPassword;

  // onChange handler for old password and new password
  const passwordOnChange = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
    updatePassReset();
  };

  // useMutation for upadting password
  const updatePassMutation = useMutation(async ({ oldPass, newPass }) => {
    const res = await updatePassword({ oldPass, newPass });
    return res;
  });

  const {
    isLoading: updatePassLoading,
    isError: updatePassIsError,
    error: updatePassError,
    reset: updatePassReset,
  } = updatePassMutation;

  // submit handler for updating password
  const updatePassSubmit = (oldPass, newPass) => {
    updatePassMutation.mutate(
      { oldPass, newPass },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("currentUser");
          toast.success("Password updated.");
          setUserPassword({ oldPass: "", newPass: "" });
        },
      }
    );
  };

  if (isLoading) {
    return <LoadingBar />;
  } else if (isError) {
    return <IsError />;
  } else
    return (
      <MainContainer rightDrawer={rightDrawer}>
        <div className="w-full p-8 space-y-4">
          <div className="mb-12">
            <PageTitle title="Profile" />
          </div>
          {/* end of alert */}
          <div className="md:space-x-6 md:flex">
            {/* profile form */}
            <form
              className={styles.profileForm}
              onSubmit={(e) => {
                e.preventDefault();
                updateProfileSubmit(name.trim(), email, password);
              }}
            >
              <h1 className="text-lg font-semibold text-blue-500">Update Profile</h1>
              <div className="flex flex-col">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className={`border  ${
                    updateProfileIsError && !validName(name)
                      ? "border-red-500"
                      : "border-gray-400"
                  } rounded-lg`}
                  onChange={onChangeHandler}
                  value={name}
                />
                {updateProfileIsError && !validName(name) ? (
                  <h1 className="block text-sm text-red-500">
                    Name must contain at least 3 alphanumeric characters. <br />
                    Space in between words is allowed.
                  </h1>
                ) : null}
              </div>

              <div className="flex flex-col">
                <label>Username</label>
                <input
                  disabled
                  type="text"
                  name="username"
                  className={styles.usernameInput}
                  value={username}
                />
              </div>

              <div className="flex flex-col">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  className={`border  ${
                    updateProfileError?.toString() === "Error: Error: 409" ||
                    (updateProfileError?.toString() === "Error: Error: 422" &&
                      !validEmail(email))
                      ? "border-red-500"
                      : "border-gray-400"
                  } rounded-lg`}
                  onChange={onChangeHandler}
                  value={email}
                />
                {updateProfileError?.toString() === "Error: Error: 409" ? (
                  <h1 className="block text-sm text-red-500">
                    Email is already taken.
                  </h1>
                ) : null}

                {updateProfileError?.toString() === "Error: Error: 422" &&
                !validEmail(email) ? (
                  <h1 className="block text-sm text-red-500">Invalid email</h1>
                ) : null}
              </div>
              <div className="flex flex-col">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter your password to save"
                  className={`border rounded-lg ${
                    updateProfileError?.toString() === "Error: Error: 401"
                      ? "border-red-500"
                      : "border-gray-400"
                  }`}
                  onChange={onChangeHandler}
                  value={password}
                />
                {updateProfileError?.toString() === "Error: Error: 401" ? (
                  <h1 className="block text-sm text-red-500">
                    Incorrect password
                  </h1>
                ) : null}
              </div>
              <div>
                <button
                  type="submit"
                  className={`${styles.saveBtn} ${
                    updateProfileLoading ? "pointer-events-none" : ""
                  }`}
                >
                  {updateProfileLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
            {/* end of profile form */}

            {/* password form */}
            <form
              className="space-y-4 md:w-1/3"
              onSubmit={(e) => {
                e.preventDefault();
                updatePassSubmit(userPassword.oldPass, userPassword.newPass);
              }}
            >
              <h1 className="text-lg font-semibold text-blue-500">Change Password</h1>
              <div className="flex flex-col">
                <label>Current Password</label>
                <input
                  type="password"
                  name="oldPass"
                  value={oldPass}
                  onChange={passwordOnChange}
                  className={`border  ${
                    updatePassIsError ? "border-red-500" : "border-gray-400"
                  } rounded-lg`}
                />
                {updatePassError?.toString() === "Error: Error: 409" ? (
                  <h1 className="text-sm text-red-500">Incorrect password</h1>
                ) : null}
              </div>
              <div className="flex flex-col">
                <label>New Password</label>
                <input
                  type="password"
                  name="newPass"
                  value={newPass}
                  onChange={passwordOnChange}
                  className={`border ${
                    updatePassIsError ? "border-red-500" : "border-gray-400"
                  } rounded-lg`}
                />
                {updatePassError?.toString() === "Error: Error: 422" ? (
                  <h1 className="text-sm text-red-500">
                    Password must contain at least 8 alphanumeric characters.
                  </h1>
                ) : null}
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className={`${styles.updatePwBtn} ${
                    updatePassLoading ? "pointer-events-none" : "cursor-pointer"
                  }`}
                >
                  {updatePassLoading ? <Spinner /> : "Update Password"}
                </button>
              </div>
            </form>
            {/* end of password form */}
          </div>
        </div>
      </MainContainer>
    );
}
