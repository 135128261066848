// ui
import toast from "react-hot-toast";
import DeleteModal from "../modals/DeleteModal";

// packages
import { useMutation, useQueryClient } from "react-query";
// api
import { deleteManyEvent } from "../../api/events";

export default function DeleteMany({ setSelected, deleteModal, data }) {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(async (data) => {
    const res = await deleteManyEvent(data);
    return res;
  });

  const { isLoading, isError, reset } = deleteMutation;

  const deleteHandler = (data) => {
    const events = [];
    data.map((event) => events.push(event.id));

    deleteMutation.mutate(events, {
      onSuccess: () => {
        queryClient.invalidateQueries("events");
        setSelected([]);
        deleteModal.onClose();
        toast.success("Event deleted.");
      },
    });
  };

  return (
    <>
      <DeleteModal
        deleteOne={false}
        deleteModal={deleteModal}
        isLoading={isLoading}
        isError={isError}
        reset={() => reset()}
        onConfirm={() => deleteHandler(data)}
        data={data}
      />
    </>
  );
}
