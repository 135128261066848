import { useState } from "react";

// ui
import { Modal, Alert, Spinner } from "flowbite-react";
import toast from "react-hot-toast";
import { styles } from "./styles/AddContact.styles";

// icons
import { FiEye, FiEyeOff } from "react-icons/fi";

// packages
import { useMutation, useQueryClient } from "react-query";

// api
import { register } from "../../api/users";

export default function AddContact({ addContactModal }) {
  const [user, setUser] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "nonadmin",
  });

  const [hidePw, setHidePw] = useState(true); //eye icon to display/hide password

  const registerOnChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    reset();
  };

  //submit register form
  const queryClient = useQueryClient();

  const registerMutation = useMutation(async (user) => {
    const res = await register(user);
    return res;
  });

  const { isLoading, isError, error, reset } = registerMutation;

  // add user form submit handler
  const registerSubmit = (user) => {
    registerMutation.mutate(user, {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        toast.success("New user added");
        addContactModal.onClose();
      },
    });
  };

  const usernameTakenError =
    isError && error?.toString() === "Error: Error: Username is taken";

  const emailTakenError =
    isError && error?.toString() === "Error: Error: Email is taken";

  const nameError = (name) => {
    const nameRegex = /^[a-zA-Z0-9]+([ \t]+[a-zA-Z0-9]+)*$/;
    return isError && (name.length < 3 || !nameRegex.test(name));
  };

  const emailError = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return isError && !emailRegex.test(email);
  };

  const passwordError = (password) => {
    return isError && password.length < 8;
  };

  // modal onClose
  const onClose = () => {
    setUser({
      name: "",
      username: "",
      email: "",
      password: "",
      role: "nonadmin",
    });
    addContactModal.onClose();
    reset();
  };

  return (
    <div>
      <Modal
        show={addContactModal.isOpen}
        size="lg"
        onClose={onClose}
      >
        <Modal.Header>New User</Modal.Header>
        <Modal.Body>
          <form className={styles.form}>
            <input
              type="text"
              name="name"
              value={user.name}
              onChange={registerOnChange}
              placeholder="Name"
              className={`${styles.input} ${
                nameError(user.name) ? "border-red-500" : "border-gray-500"
              }`}
            />
            {nameError(user.name) ? (
              <h1 className="text-sm text-red-500">
                Name must contain at least 3 characters
              </h1>
            ) : null}

            <input
              type="text"
              name="username"
              value={user.username}
              onChange={registerOnChange}
              placeholder="Username (This is permanent)"
              className={`${styles.input} ${
                usernameTakenError ? "border-red-500" : "border-gray-500"
              }`}
            />
            {usernameTakenError ? (
              <h1 className="text-sm text-red-500">Username is taken</h1>
            ) : null}

            <input
              type="email"
              name="email"
              value={user.email}
              onChange={registerOnChange}
              placeholder="Email"
              className={`${styles.input} ${
                emailTakenError || emailError(user.email)
                  ? "border-red-500"
                  : "border-gray-500"
              }`}
            />
            {emailTakenError ? (
              <h1 className="text-sm text-red-500">Email is taken</h1>
            ) : null}

            {emailError(user.email) ? (
              <h1 className="text-sm text-red-500">Email is invalid</h1>
            ) : null}

            <div
              className={`${styles.pwInputContainer} ${
                passwordError(user.password)
                  ? "border-red-500"
                  : "border-gray-500"
              }`}
            >
              <input
                type={hidePw ? "password" : "text"}
                name="password"
                value={user.password}
                onChange={registerOnChange}
                placeholder="Password"
                className={styles.pwInput}
              />
              <div className={styles.eyeIconContainer}>
                {hidePw ? (
                  <FiEyeOff
                    className={styles.eyeIcon}
                    size={16}
                    onClick={() => setHidePw(!hidePw)}
                  />
                ) : (
                  <FiEye
                    className={styles.eyeIcon}
                    size={16}
                    onClick={() => setHidePw(!hidePw)}
                  />
                )}
              </div>
            </div>

            {passwordError(user.password) ? (
              <h1 className="text-sm text-red-500">
                Password must contain at least 8 characters
              </h1>
            ) : null}

            <div className="flex flex-col">
              <label className="text-purple-500">Role</label>
              <select
                name="role"
                value={user.role}
                onChange={registerOnChange}
                className={styles.adminSelect}
              >
                <option
                  value="admin"
                  className="bg-white"
                >
                  Admin
                </option>
                <option
                  value="nonadmin"
                  className="bg-white"
                >
                  Non-Admin
                </option>
              </select>
            </div>
            <Alert
              color="info"
              additionalContent={
                <div>
                  <div className="mt-2 mb-4 text-sm text-blue-700">
                    <ul className="mt-1.5 ml-4 list-disc list-inside text-blue-700">
                      <li>
                        Name and Username must contain at least 3 characters
                      </li>
                      <li>Spaces are not allowed in all input except Name</li>
                      <li>Password must contain at least 8 characters</li>
                    </ul>
                  </div>
                </div>
              }
            >
              <h3 className="text-lg font-medium text-blue-700">
                Make sure these requirements are fulfilled
              </h3>
            </Alert>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.footer}>
            <button
              onClick={() => registerSubmit(user)}
              className={`${styles.submitBtn} ${
                isLoading ? "pointer-events-none" : "cursor-pointer"
              }`}
            >
              {isLoading ? <Spinner /> : "Save"}
            </button>
            <button
              onClick={onClose}
              className={styles.cancelBtn}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
