import { Modal, Alert, Button, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { changeRole } from "../../api/users";

const EditContactModal = ({ editModal, data }) => {
  const { id, name, role } = data;

  const queryClient = useQueryClient();

  const [userRole, setUserRole] = useState("");
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    setUserRole(role);
  }, [role]);

  const roleOnChange = (e) => {
    setUserRole(e.target.value);
    reset();
  };

  const editMutation = useMutation(
    async ({ id, role }) => {
      const res = await changeRole(id, role);
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        toast.success("User role changed");
        editModal.onClose();
      },
      onError: (error) => {
        setSubmitError(error.message);
      },
    }
  );

  const { isLoading, isError, reset } = editMutation;

  const editSubmit = (id, role) => {
    editMutation.mutate({ id, role});
  };

  const onClose = () => {
    editModal.onClose();
    reset();
  };

  return (
    <Modal
      show={editModal.isOpen}
      size="lg"
      popup
      onClose={onClose}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <h3 className="mb-2 text-lg font-semibold text-blue-500">{name}</h3>
          {isError && (
            <Alert color="failure">
              <div className="flex flex-col">
                <p className="font-medium">Failed to change {name}'s role</p>
                <p>{submitError}</p>
              </div>
            </Alert>
          )}
          <div className="flex flex-col mb-4">
            <h3 className="mb-1 text-sm text-zinc-700">Role</h3>
            <select
              value={userRole}
              name="role"
              onChange={roleOnChange}
              className="px-2 py-2 border rounded-lg appearance-none focus:border-blue-500 focus:ring-blue-500 ring-0"
            >
              <option
                value="admin"
                className="h-12 py-4"
              >
                Admin
              </option>
              <option
                value="nonadmin"
                className="text-black"
              >
                Non-Admin
              </option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="flex justify-center gap-2">
            <Button onClick={() => editSubmit(id, userRole)}>Save</Button>
            <Button
              color="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditContactModal;
