//fetch events
export const getEvents = async (queryParams) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/events/?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

// get one event by id
export const getEventById = async (id) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/events/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    });

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

// upload new event
export const uploadEvent = async (name, data, uniqueHeader) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/events/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ name, data, uniqueHeader }),
    });

    if (!res.ok) {
      const { error } = await res.json();
      throw new Error(error);
    }

    const response = await res.json();
    return response;
  } catch (error) {
    throw error;
  }
};

// update event parts
export const updateEvent = async (id, parts) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/events/update/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ data: parts }),
      }
    );

    if (res.status !== 200 || !res.ok) {
      const { error } = await res.json();
      throw new Error(error);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

//edit event's name
export const editEvent = async (id, name) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/events/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ name }),
    });

    if (!res.ok) {
      const { error } = await res.json();
      throw new Error(error);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    throw error
  }
};

//delete one event
export const deleteEvent = async (id) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/events/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });

    const data = await res.json();

    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error("Failed to delete");
  }
};

//delete many events
export const deleteManyEvent = async (events) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/events`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ id: events }),
    });

    const data = await res.json();

    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error("Failed to delete");
  }
};
