import toast from "react-hot-toast";
import DeleteModal from "../modals/DeleteModal";
import { deleteMany as deleteFn } from "../../api/users";
import { useMutation, useQueryClient } from "react-query";

export default function DeleteMany({ deleteModal, data, setSelected }) {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(async (users) => {
    const res = await deleteFn(users);
    return res;
  });

  const { isLoading, isError, reset } = deleteMutation;

  const deleteHandler = (data) => {
    const users = [];
    data.map((user) => users.push(user.id));

    deleteMutation.mutate(users, {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        setSelected([]);
        deleteModal.onClose();
        toast.success("Deleted.");
      },
    });
  };

  return (
    <DeleteModal
      deleteOne={false}
      deleteModal={deleteModal}
      isLoading={isLoading}
      isError={isError}
      reset={reset}
      onConfirm={() => deleteHandler(data)}
      data={data}
    />
  );
}
