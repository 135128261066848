import { Modal, Button } from "flowbite-react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import clsx from "clsx";

const DownloadModal = ({ downloadModal, data }) => {
  const [filename, setFilename] = useState("");
  const [emptyFilename, setEmptyFilename] = useState(false);

  const onClose = () => {
    downloadModal.onClose();
    setFilename("");
  };

  const fileNameOnChange = (e) => {
    setFilename(e.target.value);
    setEmptyFilename(false);
  };

  const downloadOnClick = (e, done) => {
    if (!data || filename === "") {
      done(false);
      setEmptyFilename(true);
      return;
    } else {
      done();
    }

    downloadModal.onClose();
  };

  const omitId = (data) => {
    return data.map((item) => {
      const { _id, ...rest } = item;

      const capitalize = {};
      for (let key in rest) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        capitalize[capitalizedKey] = rest[key];
      }

      return capitalize;
    });
  };

  return (
    <Modal
      show={downloadModal.isOpen}
      size="md"
      onClose={onClose}
    >
      <Modal.Header>Download</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-2">
          <h1 className="text-sm">File name</h1>
          <input
            onChange={fileNameOnChange}
            value={filename}
            className={clsx(
              emptyFilename ? "border-red-500" : "border-zinc-500",
              "w-full p-2 border rounded-lg focus:outline-blue-500 focus:ring-blue-500"
            )}
          />
          {emptyFilename && (
            <h1 className="text-xs italic text-red-500">
              File name is required
            </h1>
          )}
          <h1 className="text-sm text-blue-500">
            This file contains {data.length} rows.
          </h1>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {data && (
          <CSVLink
            data={omitId(data)}
            asyncOnClick={true}
            filename={filename}
            onClick={downloadOnClick}
          >
            <Button>Donwload</Button>
          </CSVLink>
        )}
        <Button
          color="gray"
          onClick={onClose}
        >
          <p>Cancel</p>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadModal;
