import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

// auth
import Login from "./pages/login/Login";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ResetPassFromEmailLink from "./pages/resetPassword/ResetPassFromEmailLink";

// event
import Events from "./pages/event";
import AddEvent from "./pages/event/AddEvent";

// participants
import Participants from "./pages/participant";
import UpdatePart from "./pages/participant/UpdatePart";

// user
import Contacts from "./pages/contact";
import Profile from "./pages/profile/Profile";

import NotFound from "./pages/404";

// routes
import GuestRoutes from "./Routes/GuestRoutes";
import UserRoutes from "./Routes/UserRoutes";
import AdminRoutes from "./Routes/AdminRoutes";

import { Toaster } from "react-hot-toast";



function App() {
  return (
    <div className="min-h-[100vh] bg-white text-black relative">
      <Toaster />

      <Routes>
        <Route
          path="*"
          element={<NotFound />}
        />
        <Route
          path="/"
          element={<Home />}
        />
        <Route element={<GuestRoutes />}>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/resetpassword"
            element={<ResetPassword />}
          />
          <Route
            path="/resetpass/:token"
            element={<ResetPassFromEmailLink />}
          />
        </Route>
        <Route element={<UserRoutes />}>
          <Route
            path="/events"
            element={<Events />}
          />
          <Route
            path="/events/add"
            element={<AddEvent />}
          />
          <Route
            path="/events/parts/:id"
            element={<Participants />}
          />
          <Route
            path="/events/parts/:id/update"
            element={<UpdatePart />}
          />
          <Route
            path="/profile"
            element={<Profile />}
          />
        </Route>
        <Route element={<AdminRoutes />}>
          <Route
            path="/contacts"
            element={<Contacts />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
