import { useMutation, useQueryClient } from "react-query";

// ui
import toast from "react-hot-toast";

// api
import { deleteEvent } from "../../api/events";
import DeleteModal from "../modals/DeleteModal";

export default function DeleteOne({ deleteModal, rightDrawer, data }) {
  const { id, name } = data;

  const queryClient = useQueryClient();

  const deleteMutation = useMutation(async (id) => {
    const res = await deleteEvent(id);
    return res;
  });

  const { isLoading, isError, reset } = deleteMutation;

  const deleteHandler = () => {
    deleteMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries("events");
        deleteModal.onClose();
        rightDrawer.closeRightDrawer();
        toast.success("Event deleted.");
      },
    });
  };

  return (
    <DeleteModal
      deleteOne={true}
      deleteModal={deleteModal}
      isLoading={isLoading}
      isError={isError}
      reset={() => reset()}
      onConfirm={deleteHandler}
      data={[name]}
    />
  );
}
