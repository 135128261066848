import { useEffect, useState, useCallback } from "react";
import { BsSearch } from "react-icons/bs";
import { styles } from "./styles/SearchEvent.styles";

export default function EventSearch({ setSearch }) {
  const [input, setInput] = useState("");

  const searchSubmit = (e) => {
    e.preventDefault();
    setSearch(input);
  };

  const reset = useCallback(() => {
    setSearch("");
    setInput("");
  }, [setSearch]);

  useEffect(() => {
    if (input.length <= 0) {
      reset();
    }
  }, [input, reset]);

  return (
    <div className={styles.mainContainer}>
      <form
        onSubmit={searchSubmit}
        className={styles.searchForm}
      >
        <input
          type="text"
          onChange={(e) => setInput(e.target.value)}
          value={input}
          className={styles.searchInput}
          placeholder="Search"
        />
        {input.length > 0 && (
          <button
            type="button"
            onClick={reset}
            className={styles.clearBtn}
          >
            Clear
          </button>
        )}
        <button
          type="submit"
          className="mx-4"
        >
          <BsSearch />
        </button>
      </form>
    </div>
  );
}
