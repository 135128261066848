import { styles } from "./styles/ContactFilter.styles";

export default function SelectRole({ query, setQuery }) {
  return (
    <div className={styles.mainContainer}>
      <button
        onClick={() => setQuery({ ...query, role: "", search: "" })}
        className={styles.all}
      >
        All
      </button>
      <button
        onClick={() => setQuery({ ...query, role: "admin", search: "" })}
        className={styles.admin}
      >
        Admin
      </button>
      <button
        onClick={() => setQuery({ ...query, role: "nonadmin", search: "" })}
        className={styles.nonAdmin}
      >
        Non-Admin
      </button>
    </div>
  );
}
