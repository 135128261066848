import { Modal, Button} from "flowbite-react";
import { SlExclamation } from "react-icons/sl";

const ErrorModal = ({ errorModal, reset }) => {
  const onConfirm = () => {
    errorModal.onClose();
    reset();
  };
  return (
    <Modal
      show={errorModal.isOpen}
      onClose={onConfirm}
      popup
      size="md"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <div className="flex justify-center mb-6">
            <SlExclamation
              size={45}
              color="gray"
            />
          </div>
          <h3 className="mb-6">
            <p className="font-medium text-red-500">Something went wrong.</p>
            <p className="text-zinc-700">
              {" "}
              Check your network connection and try submitting again.
            </p>
          </h3>
          <div className="flex justify-center gap-4">
            <Button
              className="bg-blue-400 hover:bg-blue-500"
              onClick={onConfirm}
            >
              <p>Close</p>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
