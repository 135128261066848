import somethingWentWrong from "../public/images/something_went_wrong.jpg";

const IsError = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen text-center text-zinc-700 md:text-lg">
      <img
        src={somethingWentWrong}
        alt="Something went wrong"
        className="max-h-48 sm:max-h-72 "
      />
      <h1>
        Something went wrong. Check your internet connection and try submitting again.
      </h1>
    </div>
  );
};

export default IsError;