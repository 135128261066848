import { Modal, Button, Alert } from "flowbite-react";
import { SlExclamation } from "react-icons/sl";
import { Spinner } from "flowbite-react";
import { styles } from "./styles/deleteModal.styles";

const DeleteModal = ({
  deleteOne,
  deleteModal,
  isLoading,
  isError,
  reset,
  onConfirm,
  data,
}) => {
  const onClose = () => {
    deleteModal.onClose();
    reset();
  };

  return (
    <Modal
      show={deleteModal.isOpen}
      size="md"
      popup={true}
      onClose={() => onClose()}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          {!isLoading ? (
            <>
              <SlExclamation
                className={styles.exclamationIcon}
                size={45}
                color="red"
              />
              {isError && (
                <div className="mb-4">
                  <Alert color="failure">
                    <span>
                      <span className="font-medium">Something went wrong.</span>{" "}
                      Check your network connection and try submitting again.
                    </span>
                  </Alert>
                </div>
              )}

              <div>
                <h1 className={styles.title}>
                  You are about to delete
                  <br />
                  {deleteOne ? (
                    <span className={styles.titleSpan}>{data[0]}</span>
                  ) : (
                    <div className="py-4 overflow-y-auto max-h-48">
                      {data?.map((item, index) => (
                        <h1
                          key={index}
                          className={styles.dataListItem}
                        >
                          {index + 1 + ". " + item.name}
                        </h1>
                      ))}
                    </div>
                  )}
                </h1>
              </div>
              <hr className="my-4" />
              <h1 className="text-zinc-500">
                This action is permanent and cannot be undone.
              </h1>
            </>
          ) : (
            <Spinner />
          )}

          <div className={styles.btnContainer}>
            {!isLoading ? (
              <>
                <Button
                  color="failure"
                  onClick={() => onConfirm()}
                >
                  Confirm
                </Button>
                <Button
                  color="gray"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
