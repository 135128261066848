import jwt_decode from "jwt-decode";
import moment from "moment/moment";

//register
export const register = async (user) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/users/register`, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    const data = await res.json();

    if (res.status !== 200 || !res.ok) throw new Error(data.msg);
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//login
export const login = async (user) => {
  try {
    localStorage.removeItem("token");
    const res = await fetch(`${process.env.REACT_APP_API_URI}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    const data = await res.json();

    if (res.ok && data.token !== undefined) {
      localStorage.setItem("token", data.token);
      return data;
    } else return false;
  } catch (error) {
    throw new Error(error);
  }
};

//logout
export const logout = () => {
  localStorage.removeItem("token");
  return;
};

//user's authentication
export const checkAuth = () => {
  const now = moment().valueOf();
  let user = localStorage.getItem("token")
    ? jwt_decode(localStorage.getItem("token"))
    : null;

  let isAuth =
    localStorage.getItem("token") && now < moment.unix(user.exp) ? true : false;

  let isAdmin =
    user && (user.data.role === "admin" || user.data.role === "superadmin")
      ? true
      : false;
  let isSuperAdmin = user && user.data.role === "superadmin" ? true : false;

  return { isAuth, user, isAdmin, isSuperAdmin };
};

//get current user
export const getCurrentUser = async () => {
  try {
    const user = localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : null;

    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/users/${user.data._id}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//get all users
export const getUsers = async (queryParams) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/users/?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//change user's role
export const changeRole = async (id, role) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/users/updaterole`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ id, role }),
      }
    );

    if (res.status !== 200 || !res.ok) {
      const { error } = await res.json();
      throw new Error(error);
    } else {
      const data = await res.json();
      return data;
    }

  } catch (error) {
    throw error;
  }
};

//delete one user
export const deleteOne = async (id) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/users/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error();
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//delete multiple users
export const deleteMany = async (users) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/users`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ users }),
    });

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error(res.status);
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//update user's profile
export const updateUser = async (user) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/users/edit/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(user),
    });

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error(res.status);
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//update password
export const updatePassword = async (pass) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/users/updatepass`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(pass),
      }
    );

    const data = await res.json();
    if (res.status !== 200 || !res.ok) throw new Error(res.status);
    else return data;
  } catch (error) {
    throw new Error(error);
  }
};

//get user's reset password token
export const getWithToken = async (token) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/users/token/${token}`
    );
    const data = await res.json();
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

//send reset password email
export const sendResetEmail = async (email) => {
  try {
    const findUser = await fetch(
      `${process.env.REACT_APP_API_URI}/users/email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      }
    );

    if (findUser.status === 404) return 404;

    const res = await fetch(`${process.env.REACT_APP_API_URI}/users/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });

    const data = await res.json();
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

//reset password from reset password page
export const resetPassword = async (id, password) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI}/users/resetpass/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }
    );

    if (!res.ok) return res.status;
    const data = await res.json();
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
