export const styles = {
  mainContainer: "flex w-full min-h-screen bg-white",
  pageTitle: "mb-6 text-4xl font-semibold text-blue-400 sm:text-5xl",

  //search, add and delete
  addBtn:
    "px-3 py-3 text-center bg-blue-500 rounded-full shadow-md hover:bg-blue-600",
  addIcon: "text-lg font-bold text-white",
  deleteBtn: "px-3 py-3 bg-red-500 rounded-full shadow-md hover:bg-red-600",

  editBtn: "font-medium text-blue-600 hover:underline",
  rightDrawerContainer:
    "bg-gradient-to-br from-[#3f51b5]  to-purple-500 shadow-lg",
};
